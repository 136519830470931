import React, {useState, useRef, useEffect} from 'react';
import {ICONS} from '../../Constants/Icons';
import {AllMenus} from '../../Constants/SideMenusArray';
import {useNavigate} from 'react-router-dom';
import AppEmptyList from '../../Component/AppEmptyList';
import AppConstantsMessage from '../../Constants/AppConstantsMessage';
import {addMixpanelEvent, MixpanelEvent} from '../../Models/MixPannelEnum';

const TopSearcher = () => {
  const [search_text, setSearchText] = useState('');
  const [searched_menus, setSearchedMenus] = useState([]);
  const [isSearchListOpen, setISOpenSearchList] = useState(false);

  const searchBoxRef = useRef(null); // Create a ref for the search box container
  const navigate = useNavigate();

  const menu_click = menu => {
    if (menu.href && (!menu?.sub_menus || menu?.sub_menus?.length === 0)) {
      menu?.isOpenNewTab
        ? window.open(menu.href, '_blank')
        : navigate(menu.href);
    }
    setISOpenSearchList(false);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (
        searchBoxRef.current &&
        !searchBoxRef.current.contains(event.target)
      ) {
        setISOpenSearchList(false);
      }
    };

    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className=" max-w-[250px] md:max-w-[400px] h-[40px] w-full border border-[rgba(220,220,220,1)] rounded-md flex items-center justify-start gap-2 relative px-1 py-0.5"
      ref={searchBoxRef} // Attach ref to the container
    >
      <span className="absolute left-4">{ICONS.search_icon}</span>
      <input
        placeholder="Search..."
        type="text"
        className="pl-12 h-7 flex-grow w-full border-none outline-none focus:outline-none focus:ring-0"
        value={search_text}
        onChange={e => {
          setSearchText(e.target.value);

          if (e.target.value?.length > 0) {
            setISOpenSearchList(true);
            addMixpanelEvent(MixpanelEvent.spotlight, {
              search_value: e.target.value,
              searched_items_quantity: AllMenus?.filter(mn =>
                mn.title
                  .toLowerCase()
                  .includes(search_text?.toLocaleLowerCase()),
              )?.length,
            });
          } else {
            setISOpenSearchList(false);
          }
          setSearchedMenus(
            AllMenus?.filter(mn =>
              mn.title.toLowerCase().includes(search_text?.toLocaleLowerCase()),
            ),
          );
        }}
      />

      {isSearchListOpen && (
        <div className="w-full bg-white border border-[rgba(220,220,220,1)] rounded-md absolute top-12 left-0 min-h-[200px] max-h-[200px] overflow-y-scroll">
          {searched_menus?.length > 0 &&
            searched_menus?.map(item => {
              return (
                <div
                  key={item.title}
                  className="p-2 px-4 flex items-center justify-start gap-4 cursor-pointer hover:bg-lightYellow"
                  onClick={() => menu_click(item)}>
                  <img className="w-[24px]" src={item.icon} alt="" />
                  {item?.title}
                </div>
              );
            })}

          {searched_menus?.length === 0 && (
            <div className=" my-10">
              <AppEmptyList showImage={true} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TopSearcher;
