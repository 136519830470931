import {useCallback, useEffect, useRef} from 'react';
import {usePortal} from '../../../hooks/usePortal';

const Modal = ({children, onCloseModal}) => {
  const {render} = usePortal();

  const overlay = useRef(null);
  const wrapper = useRef(null);

  const onDismiss = useCallback(() => {
    onCloseModal();
  }, []);

  const onClick = useCallback(
    e => {
      if (e.target === overlay.current || e.target === wrapper.current) {
        if (onDismiss) onDismiss();
      }
    },
    [onDismiss, overlay, wrapper],
  );

  const onKeyDown = useCallback(
    e => {
      if (e.key === 'Escape') onDismiss();
    },
    [onDismiss],
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown]);

  return render(
    <div
      ref={overlay}
      onClick={onClick}
      className=" fixed  inset-0 h-screen w-full bg-black/50 flex items-center justify-center z-[1000]">
      {children}
    </div>,
  );
};

export default Modal;
