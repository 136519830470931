import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  TextInput,
  Dimensions,
  Image,
  FlatList,
  SafeAreaView,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
import PropTypes from 'prop-types';
import Header_View from '../HeaderView';
import {AppToast} from '../../HelperClasses/AppToast';
import downArrow_Icon from '../../assets/downArrowIcon.png';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import SelectSearchView from 'react-select';
import Translations from './Translation';
import Switch from 'react-switch';
import AppColor from '../../HelperClasses/AppColor';
import CustomAlertView from '../../Component/CustomAlertView';
import AddAttributeValues from './AddAttributeValues';
import {addMixpanelEvent} from '../../Models/MixPannelEnum';

import attachmentIcon from '../../assets/attachmentIcon.png';
import booleanIcon from '../../assets/booleanIcon.png';
import colorIcon from '../../assets/colorIcon.png';
import dateandtimeIcon from '../../assets/dateandtimeIcon.png';
import jsonGrayIcon from '../../assets/jsonGrayIcon.png';
import locationGrayIcon from '../../assets/locationGrayIcon.png';
import audioIcon from '../../assets/audio.png';
import videoIcon from '../../assets/video.png';
import multisecIcon from '../../assets/multisecIcon.png';
import numberIcon from '../../assets/numberIcon.png';
import singlesecIcon from '../../assets/singlesecIcon.png';
import textIcon from '../../assets/textIcon.png';
import openmultipleIcon from '../../assets/openmultipleIcon.png';
import openSingleValueIcon from '../../assets/openSingleValueIcon.png';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import {previewStatusBarView} from '../../Component/CommonComponents';
import PreviewStyleSheet from '../../StyleSheets/PreviewStyleSheet';
import addListingsIcon from '../../assets/addListingsIcon.png';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import FilePicker from '../../Component/FilePicker';
import AppConstants from '../../Constants/AppConstants';
import {TypeEnum} from '../../Models/CommonEnum';
import {attributeType} from '../../HelperClasses/SingletonClass';
import MediaManagerView from '../../Component/MediaManagerView';
import AppConfigStyleSheet from '../../StyleSheets/AppConfigStyleSheet';
import jsonIcon from '../../assets/jsonIcon.png';
import pdfIcon from '../../assets/pdfICon.png';
import {withRouter} from '../../wrappers/withRouter';
import {loadAttributesGroup} from '../Attributes/actions/attributeGroupActions';
import SelectFromList from '../../Component/Form/SelectFromList';
import FilterOption from './Attributes/FilterOption';
import {loadAttributesAction} from '../../Actions/getAttributes';

const windowHeight = Dimensions.get('window').height;

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;
const APPURL = require('../../Constants/URLConstants');
let typesArray = [
  {name: 'Single Select', id: 1, image: singlesecIcon},
  {name: 'Multi Select', id: 2, image: multisecIcon},
  {name: 'Open (Single Value)', id: 3, image: openSingleValueIcon},
  {name: 'Open (Multi Value)', id: 4, image: openmultipleIcon},
  {name: 'Attachment', id: 5, image: attachmentIcon},
  {name: 'Text Area', id: 6, image: textIcon},
  {name: 'Date Time', id: 7, image: dateandtimeIcon},
  {name: 'Number', id: 8, image: numberIcon},
  {name: 'Boolean', id: 9, image: booleanIcon},
  {name: 'Color', id: 10, image: colorIcon},
  {name: 'Json', id: 11, image: jsonGrayIcon},
  {name: 'Location', id: 12, image: locationGrayIcon},
  {name: 'Audio', id: 13, image: audioIcon},
  {name: 'Video', id: 14, image: videoIcon},
];
var pageNo = 1;

class AddAttributes2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateUI: false,
      showDropDown: false,
      optionalStatus: false,
      privateStatus: false,
      statusSwitchValue: true,
      showFilterSwitchValue: false,
      name: '',
      dataLoad: true,
      id: '',
      selectedCategoryArray: [],
      prefillTypes: [
        {
          value: 'user',
          label: 'User',
        },
        {
          value: 'accounts',
          label: 'Accounts',
        },
        {
          value: 'listings',
          label: 'Listings',
        },
      ],
      selectedPrefillTypes: [],
      field_list: [],
      selectedFiledList: [],
      selected_prefill_for: 'first_item',
      selectedType: '',
      filter_option: 'OR',
      selectedTypeID: 0,
      categoriesArray: [],
      openSelectCategory: false,
      translationBool: false,
      addListingAttributeValueBool: false,
      showNextViewBool: false,
      attributeID: '',
      attributeName: '',
      visibiltyHiddenStatus: false,
      toolTip: '',
      switchValue: false,
      selectedImage: null,
      mediaArray: [],
      showMediaManager: false,
      attributeData: {},
      searchParams: new URLSearchParams(this.props.location.search),
      attrGroups: null,
      attribute_group_id: 0,
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    attributeData: PropTypes.any,
    isEdit: PropTypes.bool,
    type: PropTypes.string,
    categoryID: PropTypes.string,
    closeBtnAction: PropTypes.func,
  };

  componentDidMount() {
    if (this.state.searchParams.get('type') != TypeEnum.users) {
      this.setState({dataLoad: false});
      if (!this.props.isEdit) {
        this.loadCategoryApi();
        this.load_attr_groups();
      }
    }
    this.loadMediaAPi();
    const atriData = this.state.attributeData;
    const isEdit = this.props.isEdit;
    if (isEdit) {
      this.loadAttributeApi();
    }
  }

  loadAttributeApi = async () => {
    this.setState({dataLoad: false});
    this.state.currentType = this.state.searchParams.get('type');
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.attributes +
        `?type=${this.state.searchParams.get('type').toLowerCase()}`,
      'get',
    );
    if (responseJson['status'] == true) {
      var attriD = responseJson['data']['attributes'];
      // console.log('attriD', attriD)
      let atriData = attriD.filter(
        item => item.id == this.props.params.attributeID,
      )[0];
      this.state.attributeData = atriData;
      const isEdit = this.props.isEdit;
      if (isEdit) {
        if (this.state.name.length == 0) {
          let feildtype = atriData['field_type'];
          this.state.selectedTypeID = typesArray[feildtype - 1]['id'];
          this.state.selectedType = typesArray[feildtype - 1]['name'];
          this.setState({
            name: atriData['name'],
            optionalStatus: atriData['optional'],
            privateStatus: atriData['private'],
            statusSwitchValue: atriData['active'],
            showFilterSwitchValue: atriData['show_in_filter'],
            visibiltyHiddenStatus: !atriData['visible'],
            id: atriData['id'],
            toolTip: atriData['tooltip'] || '',
            selectedImage: atriData['icon_path'] ?? '',
            filter_option: atriData?.metadata?.filter_option ?? 'OR',
            categoriesArray: [],
            selectedCategoryArray: [],
            attribute_group_id: atriData['attribute_group_id'] ?? 0,
            selectedPrefillTypes: atriData?.['metadata']?.prefill_type
              ? [atriData?.['metadata']?.prefill_type]
              : [],
            selectedFiledList: atriData?.['metadata']?.field_list
              ? [atriData?.['metadata']?.field_list]
              : [],
            field_list: atriData?.['metadata']?.field_list
              ? [atriData?.['metadata']?.field_list]
              : [],
            selected_prefill_for: atriData?.['metadata']?.prefill_for ?? '',
          });
        }

        this.loadCategoryApi();
        this.load_attr_groups();
      }
    } else {
      this.alertView(responseJson);
    }
    this.setState({dataLoad: true});
  };

  load_attr_list = async ({type}) => {
    const attr = await loadAttributesAction({type});

    this.setState({
      field_list: attr?.map(att => ({
        value: att?.id,
        label: att?.name,
      })),
    });
  };

  load_attr_groups = async () => {
    this.setState({dataLoad: false});
    const list = await loadAttributesGroup({
      type: attributeType(this.state.searchParams.get('type')),
    });
    if (list?.status) {
      this.setState({attrGroups: list?.data?.attribute_groups});
    }
    this.setState({dataLoad: true});
  };

  loadMediaAPi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.media + `?page=${pageNo}&parent=0`,
      'get',
      '',
      '',
    );
    this.setState({mediaArray: []});
    if (responseJson['status'] == true) {
      var collectionD = responseJson['data']['media'];
      if (collectionD.length != 0) {
        this.setState({mediaArray: []});
        this.setState({stopPage: false});
        for (let obc of collectionD) {
          this.state.mediaArray.push(obc);
        }
      } else {
        pageNo = pageNo > 1 ? pageNo - 1 : 1;
        this.setState({stopPage: true});
      }
    }
    this.setState({dataLoad: true});
  };
  loadCategoryApi = async () => {
    let aType = attributeType(this.state.searchParams.get('type'));
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.categories +
        `${aType === 'cart_attributes' ? `listings` : aType}&parent=0`,
      'get',
      '',
      '',
    );

    // console.log('responseJson', responseJson);
    if (responseJson['status'] == true) {
      var listD = responseJson['data']['categories'];
      for (let i = 0; i < listD.length; i++) {
        this.manageData(listD[i], listD[i]['name']);
        let hierarchyD = listD[i]['sub_category'];
        if (hierarchyD.length != 0) {
          for (let j = 0; j < hierarchyD.length; j++) {
            this.manageData(
              hierarchyD[j],
              `${listD[i]['name']}-> ${hierarchyD[j]['name']}`,
            );
            let hierarchy2 = hierarchyD[j]['sub_category'];
            if (hierarchy2.length != 0) {
              for (let k = 0; k < hierarchy2.length; k++) {
                this.manageData(
                  hierarchy2[k],
                  `${hierarchyD[j]['name']}--> ${hierarchy2[k]['name']}`,
                );
                let hierarchy3 = hierarchy2[k]['sub_category'];
                if (hierarchy3.length != 0) {
                  for (let l = 0; l < hierarchy3.length; l++) {
                    this.manageData(
                      hierarchy3[l],
                      `${hierarchy2[k]['name']}---> ${hierarchy3[l]['name']}`,
                    );
                  }
                }
              }
            }
          }
        }
        //  else {
        //   this.manageData(listD[i], `${listD[i]['name']}`);
        // }
      }

      if (this.state.id) {
        let id_Index = this.state.categoriesArray.findIndex(
          x => x.parent === this.state.id,
        );
        if (this.state.categoriesArray[id_Index]) {
          this.didSelectDropDown(this.state.categoriesArray[id_Index]);
        }
      }
    }
    this.setState({dataLoad: true});
  };
  manageData(data, name) {
    var deleteEnable = true;
    if (data['sub_category']) {
      deleteEnable = data['sub_category'].length == 0 ? true : false;
      if (data['sub_category'].length !== 0) {
        deleteEnable = data['parent'] == 0 ? false : true;
      }
    }
    let hierarchyAry = data['hierarchy'];
    var hierarchyName = '';
    var level = 1;
    if (hierarchyAry.length > 0) {
      for (let a = 0; a < hierarchyAry.length; a++) {
        hierarchyName = `${hierarchyName}${hierarchyAry[a]['name']}`;
        level = hierarchyAry[a]['level'];
        if (a !== hierarchyAry.length - 1) {
          hierarchyName = hierarchyName + ' -> ';
        }
      }
    }
    let catData = {
      value: data['id'],
      parent: data['parent'],
      label: hierarchyName,
    };
    const atriData = this.state.attributeData;
    const isEdit = this.props.isEdit;

    if (isEdit) {
      let selectedData = atriData['categories'];
      for (let j = 0; j < selectedData.length; j++) {
        if (data['id'] == selectedData[j]['id']) {
          this.state.selectedCategoryArray.push(catData);
        }
      }
    } else {
      if (this.props.categoryID) {
        if (data['id'] == this.props.categoryID) {
          this.state.selectedCategoryArray.push(catData);
        }
      }
    }
    this.state.categoriesArray.push(catData);
  }
  addAttributeApi = async image => {
    // let aType = this.state.searchParams.get("type") == 'Listings' ? 'listings' : 'accounts'
    let aType = attributeType(this.state.searchParams.get('type'));
    var manualidsAry = [];
    for (let Objc of this.state.selectedCategoryArray) {
      manualidsAry.push(Objc['value']);
    }
    var dict = {
      name: this.state.name,
      active: true,
      type: aType,
      field_type: this.state.selectedTypeID,
      optional: this.state.optionalStatus,
      private: this.state.privateStatus,
      active: this.state.statusSwitchValue,
      show_in_filter: this.state.showFilterSwitchValue,
      visible: !this.state.visibiltyHiddenStatus,
      attribute_group_id: this.state.attribute_group_id,
    };
    console.log(attributeType(this.state.searchParams.get('type')));
    if (
      this.state.searchParams.get('type') != TypeEnum.users &&
      attributeType(this.state.searchParams.get('type')) !==
        TypeEnum.cart_attributes
    ) {
      dict['category_id'] = manualidsAry;
    }
    if (
      this.state.searchParams.get('type') === TypeEnum.cart_attributes &&
      this.state.selectedPrefillTypes?.length > 0 &&
      this.state.selectedFiledList?.length > 0
    ) {
      dict['metadata'] = {
        prefill_type: this.state.selectedPrefillTypes[0],
        field_list: this.state.selectedFiledList[0],
        prefill_for: this.state.selected_prefill_for,
      };
    }
    if (this.state.toolTip.length != 0) {
      dict['tooltip'] = this.state.toolTip;
    }
    if (image.length != 0) {
      dict['icon_path'] = image;
    }
    if (
      (this.state.selectedTypeID == 1 || this.state.selectedTypeID == 2) &&
      this.state.showFilterSwitchValue
    ) {
      dict['meta_data'] = {filter_option: this.state.filter_option};
    }

    var path = APPURL.URLPaths.attributes;
    if (this.state.id !== '') {
      path = APPURL.URLPaths.attributes + '/' + this.state.id;
    }
    const responseJson = await networkService.networkCall(
      path,
      this.state.id !== '' ? 'put' : 'post',
      JSON.stringify({attribute: dict}),
    );
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelInfo(true);
      this.customAlert();
      window?.FM?.trackCustomEvent('Attributes', {
        email: AppConstants.userEmail,
        attribute: dict,
      });
      // if (this.state.id !== '') {
      this.props.navigate(-1);
      // } else {
      //   let attData = responseJson['data']['attribute']
      //   this.state.attributeID = attData['id']
      //   this.state.attributeName = this.state.name
      //   this.setState({ showNextViewBool: true })
      // }
    } else {
      this.mixpanelInfo(false);
      AppToast(responseJson);
    }
  };
  uploadImageAPI = async () => {
    var imgParm = [];
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      if (fileName != null) {
        var dict = {
          name: this.state.selectedImage['name'].replace(/ /g, ''),
          type: this.state.selectedImage['type'],
        };
        imgParm.push(dict);
      }
    }
    if (imgParm != 0) {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.S3signedUploadURL,
        'POST',
        JSON.stringify({files: imgParm}),
      );
      if (responseJson['status'] == true) {
        var result = responseJson['data']['result'];
        fetch(this.state.selectedImage.base64).then(async res => {
          const file_upload_res = await networkService.uploadFileWithSignedURL(
            result[0]['signedUrl'],
            imgParm[0]['type'],
            await res.blob(),
          );
          this.addAttributeApi(result[0]['fileUri']);
        });
      } else {
        this.setState({dataLoad: true});
      }
    } else {
      this.addAttributeApi(
        this.state.selectedImage != null ? this.state.selectedImage : '',
      );
    }
  };
  mixpanelInfo(resp) {
    let properties = {success: resp};
    addMixpanelEvent(
      `${this.state.id !== '' ? 'Edit' : 'Add'} ${this.state.searchParams.get(
        'type',
      )} Attributes`,
      properties,
    );
  }
  customAlert() {
    AppToast();
  }
  //MARK:-  Button Actions
  onPick = image => {
    if (image.length != 0) {
      this.setState({selectedImage: image[0]});
    }
  };
  closeBtnAction() {
    this.props.navigate(-1);
    this.setState({showNextViewBool: false});
  }
  submitBtnAction = () => {
    console.log('this.state.selectedTypeID ', this.state.selectedTypeID);
    if (this.state.name.length == 0) {
      AppToast('Please enter attribute name');
    } else if (this.state.selectedTypeID == 0) {
      AppToast('Please select type');
    } else if (
      this.state.searchParams.get('type') != TypeEnum.users &&
      attributeType(this.state.searchParams.get('type')) !==
        TypeEnum.cart_attributes &&
      this.state.selectedCategoryArray.length == 0
    ) {
      AppToast('Please select category');
    } else {
      this.setState({dataLoad: false});
      this.uploadImageAPI();
    }
  };
  didSelectDropDown = item => {
    this.setState({
      showDropDown: false,
      selectedType: item['name'],
      selectedTypeID: item['id'],
    });
  };
  handleSelectChange = selectedOption => {
    this.state.selectedCategoryArray = selectedOption;
    this.setState({updateUI: !this.state.updateUI});
  };
  handleSelectFillTYpeChange = selectedOption => {
    const item = selectedOption.filter(
      it => it.value !== this.state.selectedPrefillTypes?.[0]?.value,
    );
    this.state.selectedPrefillTypes = item;
    this.state.selectedFiledList = [];
    if (item?.[0]?.value === 'listings' || item?.[0]?.value === 'accounts') {
      this.load_attr_list({type: item?.[0]?.value});
    } else {
      this.setState({
        field_list: [
          {
            value: 'email',
            label: 'Email',
          },
          {
            value: 'first_name',
            label: 'First Name',
          },
          {
            value: 'last_name',
            label: 'Last Name',
          },
          {
            value: 'profile_pic',
            label: 'Profile Pic',
          },
        ],
      });
    }

    this.setState({updateUI: !this.state.updateUI});
  };
  handleSelectFieldChange = selectedOption => {
    const item = selectedOption.filter(
      it => it.value !== this.state.selectedFiledList?.[0]?.value,
    );
    this.state.selectedFiledList = item;

    this.setState({updateUI: !this.state.updateUI});
  };

  switchBtnAction(id) {
    if (id == 1) {
      this.setState({optionalStatus: !this.state.optionalStatus});
    } else if (id == 4) {
      this.setState({statusSwitchValue: !this.state.statusSwitchValue});
    } else if (id == 3) {
      this.setState({showFilterSwitchValue: !this.state.showFilterSwitchValue});
    } else if (id == 5) {
      this.setState({visibiltyHiddenStatus: !this.state.visibiltyHiddenStatus});
    } else {
      this.setState({privateStatus: !this.state.privateStatus});
    }
  }
  selectAllBtnAction = () => {
    this.state.selectedCategoryArray = this.state.categoriesArray;
    this.setState({updateUI: !this.state.updateUI});
  };
  unSelectAllBtnAction() {
    this.state.selectedCategoryArray = [];
    this.setState({updateUI: !this.state.updateUI});
  }
  addAttributeValueBtnAction() {
    this.state.name = '';
    this.state.selectedCategoryArray = [];
    this.setState({
      addListingAttributeValueBool: true,
      showNextViewBool: false,
    });
  }
  showMediaBtnAction() {
    if (this.state.mediaArray != 0) {
      this.setState({showMediaManager: true});
    } else {
      AppToast('You dont have media file.');
    }
  }
  onUpdateFilterCondition(status) {
    this.setState({filter_option: status});
  }
  // UI Renders
  imageView = props => {
    var view = [];
    view.push(
      <View style={{flexDirection: 'row'}}>
        <View style={commonStyle.addImageViewStyle}>
          <FilePicker
            title={'Add Icon'}
            fileType={['image/*']}
            data={files => this.onPick(files)}
          />
        </View>
        <View style={{width: 20, height: 20}} />
        <View style={commonStyle.addImageViewStyle}>
          <TouchableOpacity
            style={{width: 100}}
            onPress={() => this.showMediaBtnAction()}>
            <Text style={AppConfigStyleSheet.chooseFileTxtStyle}>
              Select from media
            </Text>
          </TouchableOpacity>
        </View>
        <View style={{width: 20, height: 20}} />
      </View>,
    );
    if (this.state.selectedImage != null) {
      let fileName = this.state.selectedImage.base64;
      var showImage = true;
      var icon = jsonIcon;
      var fileExt = '';
      if (fileName == undefined) {
        let name = this.state.selectedImage.substring(
          this.state.selectedImage.lastIndexOf('/') + 1,
        );
        var fileExt = name.split('.').pop();
      }
      if (fileExt == 'json') {
        icon = jsonIcon;
        showImage = false;
      } else if (fileExt == 'pdf') {
        icon = pdfIcon;
        showImage = false;
      }
      view.push(
        <View>
          <View
            style={{
              width: itsMobileView ? 20 : 0,
              height: itsMobileView ? 20 : 0,
            }}
          />
          <Image
            source={
              showImage
                ? {
                    uri:
                      fileName != null
                        ? this.state.selectedImage.base64
                        : this.state.selectedImage,
                  }
                : icon
            }
            style={commonStyle.ImageViewStyle}
          />
        </View>,
      );
    }
    return (
      <View style={{flexDirection: itsMobileView ? 'column' : 'row'}}>
        {view}
      </View>
    );
  };
  customDropDown = id => {
    if (this.state.showDropDown == true) {
      return (
        <View style={commonStyle.showDropDownViewStyle}>
          <FlatList
            data={typesArray}
            horizontal={false}
            renderItem={this.renderDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDropDown(item, index)}
        style={{margin: 5, height: 30, marginBottom: 5}}>
        <View style={{justifyContent: 'center', width: '100%'}}>
          <Image
            style={{height: 15, width: 15, backgroundColor: 'red'}}
            source={downArrow_Icon}
          />
          <Text style={CommonStyleSheet.dropdownCellItemTextStyle}></Text>
        </View>
      </TouchableOpacity>
    );
  };
  renderValueLabel = id => {
    return (
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <Text
          style={
            this.state.selectedType == ''
              ? commonStyle.placeholderTxtStyle
              : commonStyle.dropDowntxtFieldStyle
          }>
          {this.state.selectedType == ''
            ? 'Select Type'
            : this.state.selectedType}
        </Text>
        <Image
          style={
            this.state.showDropDown == true
              ? commonStyle.upArraowIconStyle
              : commonStyle.downArraowIconStyle
          }
          resizeMode="contain"
          source={downArrow_Icon}
        />
      </View>
    );
  };
  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={commonStyle.titleNameStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    );
  };
  loaderView = () => {
    return <Appload enable={this.state.dataLoad} />;
  };
  renderSwitches = ({name, id}) => {
    var statusValue = this.state.statusSwitchValue;
    if (id == 1) {
      statusValue = this.state.optionalStatus;
    } else if (id == 2) {
      statusValue = this.state.privateStatus;
    } else if (id == 3) {
      statusValue = this.state.showFilterSwitchValue;
    } else if (id == 5) {
      statusValue = this.state.visibiltyHiddenStatus;
    }
    return (
      <View style={commonStyle.switchViewStyle}>
        <Text style={commonStyle.titleNameStyle}>{name}</Text>
        <View style={{width: itsMobileView ? '12%' : '80%'}}>
          <Switch
            onColor={colors.AppNewGreen}
            onChange={() => this.switchBtnAction(id)}
            checked={statusValue}
            height={22}
            width={50}
          />
        </View>
      </View>
    );
  };

  renderSelectAllView = () => {
    if (this.state.categoriesArray != 0) {
      return (
        <View>
          <View style={{flexDirection: 'row'}}>
            <TouchableOpacity onPress={() => this.selectAllBtnAction()}>
              <Text style={styles.selectAllTextStyle}>Select All</Text>
            </TouchableOpacity>
            <Text>{`   ||   `}</Text>
            <TouchableOpacity onPress={() => this.unSelectAllBtnAction()}>
              <Text style={styles.selectAllTextStyle}>UnSelect All</Text>
            </TouchableOpacity>
          </View>
          <View style={[styles.dropDownViewStyle, {borderWidth: 0}]}>
            <View style={{width: '100%'}}>
              <SelectSearchView
                value={this.state.selectedCategoryArray}
                onMenuOpen={() => console.log('open drop')}
                onChange={itm => this.handleSelectChange(itm)}
                options={this.state.categoriesArray}
                isMulti={true}
                disabled={true}
                placeholder={`Select category`}
                styles={colourStyles}
                theme={theme => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: colors.AppGreenBorder,
                    primary: colors.AppGreenBorder,
                  },
                })}
              />
            </View>
          </View>
        </View>
      );
      // }
    } else {
      return (
        <View>
          <View style={styles.dropDownViewStyle}>
            <Text style={commonStyle.emptyCatTxtStyle}>
              {`Create categories in ${this.state.searchParams.get(
                'type',
              )} configuration firsts`}
            </Text>
          </View>
        </View>
      );
    }
  };
  renderTypeView = () => {
    var view = [];
    for (let a = 0; a <= typesArray.length - 1; a++) {
      let item = typesArray[a];
      let disable = this.state.id !== '' ? true : false;
      view.push(
        <TouchableOpacity
          disabled={disable}
          style={
            this.state.selectedTypeID == item['id']
              ? styles.selecedTypesViewStyle
              : styles.typesViewStyle
          }
          onPress={id => this.didSelectDropDown(item)}>
          <Image style={styles.iconImageViewStyle} source={item['image']} />
          <Text style={commonStyle.attrbuteSubTypetitleStyle}>
            {item['name']}
          </Text>
        </TouchableOpacity>,
      );
    }
    return <View style={styles.typesViewContainerStyle}> {view}</View>;
  };
  renderAlertView = () => {
    let showNextButton =
      this.state.selectedTypeID == 1 || this.state.selectedTypeID == 2
        ? true
        : false;
    return (
      <CustomAlertView
        showNextViewBool={this.state.showNextViewBool}
        titleValue={'Nice 🎉, Successfully added attributes'}
        nextBtnTitle={'Add Attribute Values'}
        closeBtnAction={() => this.closeBtnAction()}
        showNextButton={showNextButton}
        nextBtnAction={() => this.addAttributeValueBtnAction()}
      />
    );
  };
  renderAttributeTypePreview = () => {
    if (this.state.selectedTypeID == 1) {
      return (
        <Text style={commonStyle.previewFieldStyle}>
          Select Single Attribute Values
        </Text>
      );
    } else if (this.state.selectedTypeID == 2) {
      return (
        <Text style={commonStyle.previewFieldStyle}>
          Select Mulitple Attribute Values
        </Text>
      );
    } else if (this.state.selectedTypeID == 3) {
      return <Text style={commonStyle.previewFieldStyle}>Enter Value</Text>;
    } else if (this.state.selectedTypeID == 4) {
      return (
        <Text style={commonStyle.previewFieldStyle}>Enter Multiple Tag</Text>
      );
    } else if (this.state.selectedTypeID == 5) {
      return <Text style={commonStyle.previewFieldStyle}>Upload file</Text>;
    } else if (this.state.selectedTypeID == 6) {
      return (
        <Text
          style={[
            commonStyle.previewFieldStyle,
            {
              height: 60,
              borderWidth: 1,
              borderColor: colors.SimonGray,
              padding: 5,
              borderRadius: 5,
            },
          ]}>
          Enter Text
        </Text>
      );
    } else if (this.state.selectedTypeID == 7) {
      return <Text style={commonStyle.previewFieldStyle}>Select Date</Text>;
    } else if (this.state.selectedTypeID == 8) {
      return <Text style={commonStyle.previewFieldStyle}>Enter Number</Text>;
    } else if (this.state.selectedTypeID == 9) {
      return (
        <View style={{marginTop: 10}}>
          <Switch
            onColor={colors.AppNewGreen}
            onChange={() =>
              this.setState({switchValue: !this.state.switchValue})
            }
            checked={this.state.switchValue}
            height={20}
            width={40}
          />
        </View>
      );
    } else if (this.state.selectedTypeID == 10) {
      return <Text style={commonStyle.previewFieldStyle}>Select Color</Text>;
    } else if (this.state.selectedTypeID == 11) {
      return <Text style={commonStyle.previewFieldStyle}>Upload Json</Text>;
    } else if (this.state.selectedTypeID == 12) {
      return <Text style={commonStyle.previewFieldStyle}>Select Location</Text>;
    }
    // Select Attribute Values
  };
  renderPreviewView = () => {
    var categoryLbl = 'Select Catrgory';
    if (this.state.selectedCategoryArray[0]) {
      categoryLbl = this.state.selectedCategoryArray[0]['label'];
    }
    var addView = [];
    let title =
      this.state.searchParams.get('type') == 'Listings'
        ? 'Add Listing'
        : 'Add Account';
    if (this.state.searchParams.get('type') == 'Listings') {
      addView.push(
        <View>
          <Text style={commonStyle.previewFieldStyle}>Price</Text>
          <Text style={commonStyle.previewFieldStyle}>Enter Price</Text>
          <View
            style={{
              height: 1,
              marginTop: 5,
              width: '100%',
              backgroundColor: colors.SimonGray,
            }}
          />
        </View>,
      );
    }

    return (
      <View style={{width: 320, height: '100%', alignSelf: 'center'}}>
        <View style={{alignSelf: 'center'}}>
          <Text style={tableStyle.listTitleText}>Preview</Text>
        </View>
        <View
          style={[
            PreviewStyleSheet.previewFrameStyle,
            {backgroundColor: colors.AppNewGreen},
          ]}>
          <View>
            {previewStatusBarView()}
            <View style={{alignItems: 'center', marginTop: 5}}>
              <Text style={PreviewStyleSheet.previewMainTitleStyle}>
                {title}
              </Text>
            </View>
            <View
              style={{
                height: '100%',
                backgroundColor: colors.AppWhite,
                marginTop: 10,
              }}>
              <Image
                source={addListingsIcon}
                style={{width: 285, height: 120}}
              />
              <View style={{margin: 10}}>
                <Text style={commonStyle.previewFieldStyle}>Title</Text>
                <Text style={commonStyle.previewFieldStyle}>Enter Title</Text>
                <View
                  style={{
                    height: 1,
                    marginTop: 5,
                    width: '100%',
                    backgroundColor: colors.SimonGray,
                  }}
                />
                <Text style={commonStyle.previewFieldStyle}>Description</Text>
                <Text style={commonStyle.previewFieldStyle}>
                  Enter Description
                </Text>
                <View
                  style={{
                    height: 1,
                    marginTop: 5,
                    width: '100%',
                    backgroundColor: colors.SimonGray,
                  }}
                />
                {addView}
                <Text style={commonStyle.previewFieldStyle}>Category</Text>
                <Text style={commonStyle.previewFieldStyle}>{categoryLbl}</Text>
                <View
                  style={{
                    height: 1,
                    marginTop: 5,
                    width: '100%',
                    backgroundColor: colors.SimonGray,
                  }}
                />
                <View
                  style={{
                    display: this.state.name.length == 0 ? 'none' : 'flex',
                  }}>
                  <Text style={commonStyle.previewFieldStyle}>
                    {this.state.name}
                  </Text>
                  <Text style={commonStyle.previewToolTipStyle}>
                    {this.state.toolTip}
                  </Text>
                  {this.renderAttributeTypePreview()}
                  <View
                    style={{
                      height: 1,
                      marginTop: 5,
                      width: '100%',
                      backgroundColor: colors.SimonGray,
                    }}
                  />
                </View>
              </View>
              <View style={{height: 100}} />
            </View>
          </View>
        </View>
        <View style={{height: 40}} />
      </View>
    );
  };

  renderCategoryView = () => {
    return (
      <View
        style={{
          display:
            this.state.searchParams.get('type') != TypeEnum.users
              ? 'flex'
              : 'none',
        }}>
        <View style={{height: 20}} />
        {attributeType(this.state.searchParams.get('type')) !==
          TypeEnum.cart_attributes && (
          <View style={styles.selectCategoryViewStyle}>
            <this.titleLblRender title={'Select category'} />
            <View
              style={{
                width: itsMobileView ? '100%' : '80%',
                marginTop: itsMobileView ? 10 : 0,
              }}>
              {this.renderSelectAllView()}
            </View>
          </View>
        )}
      </View>
    );
  };

  renderPreFillTypeView = () => {
    return (
      <View
        style={{
          display:
            this.state.searchParams.get('type') != TypeEnum.users
              ? 'flex'
              : 'none',
        }}>
        <View style={{height: 20}} />
        {attributeType(this.state.searchParams.get('type')) ===
          TypeEnum.cart_attributes && (
          <View style={styles.selectCategoryViewStyle}>
            <Text style={commonStyle.titleNameStyle}>
              {'Prefill Data From'}
            </Text>
            <View
              style={{
                width: itsMobileView ? '100%' : '80%',
                marginTop: itsMobileView ? 10 : 0,
              }}>
              <View>
                <View style={[styles.dropDownViewStyle, {borderWidth: 0}]}>
                  <View style={{width: '100%'}}>
                    <SelectSearchView
                      value={this.state.selectedPrefillTypes}
                      onMenuOpen={() => console.log('open drop')}
                      onChange={itm => this.handleSelectFillTYpeChange(itm)}
                      options={this.state.prefillTypes}
                      isMulti={true}
                      disabled={true}
                      placeholder={`Select Type`}
                      styles={colourStyles}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: colors.AppGreenBorder,
                          primary: colors.AppGreenBorder,
                        },
                      })}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        )}
      </View>
    );
  };
  renderPreFillFieldView = () => {
    return (
      <View
        style={{
          display:
            this.state.searchParams.get('type') != TypeEnum.users
              ? 'flex'
              : 'none',
        }}>
        <View style={{height: 20}} />
        {attributeType(this.state.searchParams.get('type')) ===
          TypeEnum.cart_attributes && (
          <View style={styles.selectCategoryViewStyle}>
            <Text style={commonStyle.titleNameStyle}>{'Select Attribute'}</Text>
            <View
              style={{
                width: itsMobileView ? '100%' : '80%',
                marginTop: itsMobileView ? 10 : 0,
              }}>
              <View>
                <View style={[styles.dropDownViewStyle, {borderWidth: 0}]}>
                  <View style={{width: '100%'}}>
                    <SelectSearchView
                      value={this.state.selectedFiledList}
                      onMenuOpen={() => console.log('open drop')}
                      onChange={itm => this.handleSelectFieldChange(itm)}
                      options={this.state.field_list}
                      isMulti={true}
                      disabled={true}
                      placeholder={`Select Type`}
                      styles={colourStyles}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: colors.AppGreenBorder,
                          primary: colors.AppGreenBorder,
                        },
                      })}
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
        )}
      </View>
    );
  };

  renderAttrGroupView = () => {
    return (
      <View
        style={{
          display:
            this.state.searchParams.get('type') != TypeEnum.users
              ? 'flex'
              : 'none',
        }}>
        <View style={{height: 20}} />
        <View style={styles.selectCategoryViewStyle}>
          <Text style={commonStyle.titleNameStyle}>
            {'Select Attribute Group'}
          </Text>

          <View
            style={{
              width: itsMobileView ? '100%' : '80%',
              marginTop: itsMobileView ? 10 : 0,
            }}>
            <SelectFromList
              list={this?.state?.attrGroups?.map(gr => ({
                value: gr.id,
                label: gr?.name,
              }))}
              selected_value={this.state.attribute_group_id}
              setValue={v => this.setState({attribute_group_id: v})}
            />
          </View>
        </View>
      </View>
    );
  };
  mediaManagerView = () => {
    if (this.state.showMediaManager) {
      return (
        <View
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 9920,
          }}>
          <MediaManagerView
            mediaData={this.state.mediaArray}
            closeBtn={() => this.setState({showMediaManager: false})}
            onSelectBtn={path =>
              this.setState({showMediaManager: false, selectedImage: path})
            }
          />
        </View>
      );
    } else {
      return <View />;
    }
  };
  render() {
    const isEdit = this.props.isEdit;
    if (this.state.translationBool) {
      return (
        <Translations
          title={'Attribute'}
          backBtnAction={() => this.setState({translationBool: false})}
          data={this.state.attributeData}
        />
      );
    } else if (this.state.addListingAttributeValueBool) {
      return (
        <View style={{flexDirection: 'column'}}>
          <AddAttributeValues
            type={this.state.searchParams.get('type')}
            attributeName={this.state.attributeName}
            attributeID={this.state.attributeID}
            closeBtnAction={() => this.closeBtnAction()}
            backBtnAction={() =>
              this.setState({addListingAttributeValueBool: false})
            }
          />
        </View>
      );
    } else {
      return (
        <SafeAreaView style={commonStyle.Container}>
          {this.renderAlertView()}
          {this.mediaManagerView()}
          <Header_View
            title={isEdit ? 'Edit Attribute' : 'Add Attribute'}
            backBtn={() => this.props.navigate(-1)}
            saveBtn={() => this.submitBtnAction()}
          />
          <ScrollView style={{height: windowHeight / 1.15}}>
            <View
              style={{
                height: itsMobileView ? '100%' : windowHeight / 1.16,
                flexDirection: itsMobileView ? 'column' : 'row',
              }}>
              <View
                style={{flex: itsMobileView ? 1 : 1.6, height: windowHeight}}>
                {/* <ScrollView> */}
                <View style={commonStyle.mainView}>
                  <View style={{height: 20}} />
                  <View style={commonStyle.subViewStyle}>
                    <this.titleLblRender title={'Attribute name'} />
                    <View style={{width: itsMobileView ? '100%' : '80%'}}>
                      <TextInput
                        style={[commonStyle.fulltxtFieldStyle, {outline: 0}]}
                        value={this.state.name}
                        placeholder="Enter attribute name"
                        placeholderTextColor={colors.placeholderColor}
                        onChangeText={name => this.setState({name: name})}
                      />
                    </View>
                  </View>
                  <View style={{height: 20}} />
                  <View style={commonStyle.firstSubViewStyle}>
                    <this.titleLblRender title={'Select type'} />
                    <View style={{width: '80%'}}>
                      <this.renderTypeView />
                    </View>
                  </View>
                  <View style={{height: 20}} />
                  <View style={commonStyle.subViewStyle}>
                    <Text style={commonStyle.titleNameStyle}>Icon</Text>
                    <View style={{width: '80%'}}>
                      <this.imageView />
                    </View>
                  </View>
                  <View style={{height: 20}} />
                  <View style={commonStyle.subViewStyle}>
                    <Text style={commonStyle.titleNameStyle}>{'Tool tip'}</Text>
                    <View style={{width: itsMobileView ? '100%' : '80%'}}>
                      <TextInput
                        style={[commonStyle.fulltxtFieldStyle, {outline: 0}]}
                        value={this.state.toolTip}
                        placeholder="Enter attribute tool tip"
                        placeholderTextColor={colors.placeholderColor}
                        onChangeText={tip => this.setState({toolTip: tip})}
                      />
                    </View>
                  </View>
                  <View style={{zIndex: 101}}>
                    <this.renderCategoryView />
                  </View>
                  {this?.state?.attrGroups?.length > 0 && (
                    <View style={{zIndex: 101}}>
                      <this.renderAttrGroupView />
                    </View>
                  )}
                  <View style={{zIndex: 11}}>
                    <View style={{height: 20}} />
                    <this.renderSwitches name={'Optional'} id={1} />
                    <View style={{height: 20}} />
                    <this.renderSwitches name={'Private'} id={2} />
                    <View style={{height: 20}} />
                    <this.renderSwitches name={'Hidden field'} id={5} />
                    <View style={{height: 20}} />
                    <View
                      style={{
                        display:
                          TypeEnum.users === this.state.searchParams.get('type')
                            ? 'none'
                            : 'flex',
                      }}>
                      <this.renderSwitches name={'Show in filter'} id={3} />
                      <View style={{height: 20}} />
                    </View>
                    {(this?.state?.selectedType === 'Single Select' ||
                      this?.state?.selectedType === 'Multi Select') &&
                      this.state.showFilterSwitchValue && (
                        <FilterOption
                          current_status={this.state.filter_option}
                          onUpdateStatus={status =>
                            this.onUpdateFilterCondition(status)
                          }
                        />
                      )}
                    <this.renderSwitches
                      name={'Status(Not Active / Active) '}
                      id={4}
                    />
                  </View>

                  {/*  */}
                  {attributeType(this.state.searchParams.get('type')) ===
                    TypeEnum.cart_attributes &&
                    this.state.selectedTypeID === 3 && (
                      <View>
                        <View style={{zIndex: 102}}>
                          <this.renderPreFillTypeView />
                        </View>
                        {this.state.field_list?.length > 0 && (
                          <View style={{zIndex: 101}}>
                            <this.renderPreFillFieldView />
                          </View>
                        )}
                        {this.state.field_list?.length > 0 && (
                          <FilterOption
                            current_status={this.state.selected_prefill_for}
                            onUpdateStatus={status =>
                              this.setState({
                                selected_prefill_for: status,
                              })
                            }
                          />
                        )}
                        {/*  */}
                        <View style={{height: 100}} />
                      </View>
                    )}
                </View>
                <View style={{height: 100}} />
                {/* </ScrollView> */}
              </View>
              {attributeType(this.state.searchParams.get('type')) !==
                TypeEnum.cart_attributes && (
                <View style={commonStyle.mainView}>
                  {this.renderPreviewView()}
                </View>
              )}
            </View>
          </ScrollView>
          <this.loaderView />
        </SafeAreaView>
      );
    }
  }
}

export default withRouter(AddAttributes2);

const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: AppColor.AppLightGreen,
  },
  selectCategoryViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 5,
    zIndex: 20,
  },
  dropDownViewStyle: {
    marginTop: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    borderRadius: 5,
    flexDirection: 'column',
    alignItems: 'center',
    borderColor: colors.AppGreenBorder,
    width: '100%',
  },
  selectAllTextStyle: {
    textDecorationLine: 'underline',
    color: colors.blueColor,
  },
  typesViewContainerStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: 'white',
    width: itsMobileView ? windowWidth / 1.2 : '100%',
    marginLeft: itsMobileView ? 0 : -10,
  },
  typesViewStyle: {
    marginRight: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 90,
    width: 90,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
  },
  selecedTypesViewStyle: {
    marginRight: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.Appgreen,
    borderWidth: 1,
    height: 90,
    width: 90,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
  },
  iconImageViewStyle: {
    height: 20,
    width: 20,
    objectFit: 'cover',
  },
  categoryViewStyle: {
    backgroundColor: colors.SimonGray,
    padding: 5,
    borderRadius: 5,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 2,
    margin: 5,
  },
});
const colourStyles = {
  control: styles => ({...styles, backgroundColor: 'white', borderRadius: 5}),
  option: (styles, {data, isDisabled, isFocused, isSelected}) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? colors.SimonGray
        : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? colors.SimonGray
          ? colors.AppRed
          : 'black'
        : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : colors.Lightgray),
      },
    };
  },
  multiValueLabel: (styles, {data}) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, {data}) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: colors.AppRed,
    },
  }),
};

// Sandeep Harchand 78378-68008
