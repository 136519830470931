import React, {useEffect, useState} from 'react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import RootView from './RootView';
import {Component} from 'react';
import {
  View,
  Text,
  SafeAreaView,
  StyleSheet,
  Linking,
  Dimensions,
} from 'react-native';
import {createStackNavigator, TransitionPresets} from '@react-navigation/stack';
import CreateApp from '../CreateTenantID/CreateYourApp';
import Business from '../CreateTenantID/BusinessFrom';
import SelectPlatformScreen from '../CreateTenantID/SelectPlatform';
import PGateWayScreen from '../CreateTenantID/SelectPaymentPlatform';
import ShipScreen from '../CreateTenantID/SelectShippingPlatform';
import ChooseMap from '../CreateTenantID/ChooseLocationMap';
import RegTypes from '../CreateTenantID/RegistrationTypePlatform';
import FScreen from '../CreateTenantID/FinalScreen';
import DashboardScreens from '../Dashboard/DashboardScreen';
import networkService from '../Network/NetworkManager';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import AppConstants from '../Constants/AppConstants';
import {
  getCurrentUser,
  saveAdminData,
  saveUserData,
  clearStorage,
  getMemberCookiesValue,
} from '../HelperClasses/UserPrefrences';
import LoginScreen from './LoginScreen';
import InviteMember from '../InviteMember/InviteMember';
import LogRocket, {log} from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import mixpanel from 'mixpanel-browser';

import Flavours from '../CreateTenantID/Flavour';
import {getProductAccess, getQueryParam} from '../HelperClasses/SingletonClass';
import {ProjectTypeModel} from '../Models/ProjectTypeModel';
import LandingPage from '../LandingPage/LandingPage';
import {setupUserPropertyOnMixpanel} from '../Models/MixPannelEnum';
import AppColor from '../HelperClasses/AppColor';
import Reports from '../Report/Report';
import MobileAppConfig from '../Settings/MobileAppConfig/MobileAppConfig';
import Orders from '../Orders/Orders';
import Transaction from '../Transactions/Transactions';
import Setting from '../Settings/Settings';
import Collections from '../Collections/AllCollection';
import MainListings from '../MainListing/MainListing';
import AccountLists from '../Accounts/AccountList';
import PromoBanners from '../PromoBanner/PromoBanner';
import ReviewRatings from '../ReviewRate/ReviewRating';
import MediaManagers from '../MediaManager/MediaManager';
import {
  DeliverySideMenuArray,
  SideAppSubMenuArray,
  SideMenuAccountsArray,
  SideMenuArray,
  SideMenuListingArray,
  SidePromotionsMenuArray,
  SideWebSubMenuArray,
} from '../Constants/ConstantArray';
import Channel from '../Channel/Channel';
import NativeIntegrations from '../Settings/NativeIntegrations/NativeIntegrations';
import AppTranslation from '../Settings/AppTranslation/AppTranslation';
import SEOSetting from '../Settings/SEOSettings/SEOSetting';
import DeliveryOrders from '../Delivery/Orders/DeliveryOrders';
import Branches from '../Delivery/Branches/Branches';
import Fleets from '../Delivery/Fleets/Fleets';
import Drivers from '../Delivery/Drivers/Drivers';
import AuditLogs from '../Delivery/AuditLogs/AuditLogs';
import Roster from '../Delivery/Roster/Roster';
import DeliveryDashboard from '../Delivery/DeliveryDashboard/DeliveryDashboard';
import GetStarted from '../Delivery/GetStart/GetStarted';
import Notifications from '../Settings/Notifications/Notifications';
import {addMixpanelEvent, MixpanelEvent} from '../Models/MixPannelEnum';
import Coupons from '../Coupons/Coupons';
import CategoryList from '../Settings/Listings/CategoryList';
import AttributesList from '../Settings/Listings/AttributesList';
import ListingVariantType from '../Settings/Listings/ListingVariantType';
import ListingSetting from '../Settings/GlobalSettings/ListingSetting';
import {TypeEnum} from '../Models/CommonEnum';
import AccountSetting from '../Settings/GlobalSettings/AccountSetting';
import Users from '../Users/Users';

import Commision from '../RevenueModel/Commissions';
import ShippingMethod from '../Settings/ShippingMethod/ShippingMethods';
import PaymentMethod from '../Settings/PaymentMethod/PaymentMethods';
import Currencies from '../Currency/ListCurrencies';
import Developers from '../Settings/Developer/DeveloperMain';
import Subscriptions from '../Settings/Subscription/SubscriptionMain';
// import Language from '../Settings/Languages/Languages';
import SpecialFees from '../Settings/SpecialFees/SpecialFeesContainer';
import LanguageLists from '../Settings/Languages/LanguageList';
import AppCountry from '../Settings/AppCountries/AppCountries';
import Checkouts from '../Settings/Checkout/Checkout';

import Taxes from '../Settings/Taxes/Taxes';
import Auth from '../Settings/Auth/Auth';
import Error from '../Constants/Error';
import Test from '../Test/Test';
import ParentTest from '../Test/ParentTest';
import AddListings from '../MainListing/AddListings';
import OrderDetails2 from '../Orders/OrderDetails2';
import {getSelectedTenantDetails} from '../Constants/Functions';
import RootView3 from './RootView3';
import ListingDetail from '../MainListing/ListingDetail';
import ListingDetail2 from '../MainListing/ListingDetail2';
import AccountDetail2 from '../Accounts/AccountDetail2';
import UserDetail2 from '../Users/UserDetail2';
import AddAttributes from '../Settings/Listings/AddAttributes';
import AddAttributes2 from '../Settings/Listings/AddAttributes2';
import CreateCollection from '../Collections/CreateCollection';
import AddPromoBanner from '../PromoBanner/AddPromoBanner';
import AddCategory from '../Settings/Listings/AddCategory';
import AddCategory2 from '../Settings/Listings/AddCategory2';
import OrderDetails from '../Orders/OrderDetails';
import {CONFIG_CONSTANT, set_root_configs} from '../Constants/ConfigsConstants';
import ReportedListings from '../ReportedListings/ReportedListings';
import ReportedAccounts from '../ReportedAccounts/ReportedAccounts';
import CreateCollection2 from '../Collections/CreateCollection2';
import AddCoupon from '../Coupons/AddCoupon';
// import OrderDetails2 from '../Orders/OrderDetails2';
import DashboardScreen2 from '../Dashboard/DashboardScreen2';
import Error404 from '../Component/Error/Error404';
import MainRootView from './MainRootView';
import CategoryList2 from '../Settings/Listings/CategoryList2';
import CategoryDescriptionTranslate from '../Settings/Listings/CategoryDescriptionTranslate';
import ListingsConfiguration from '../Settings/GlobalSettings/Listing/ListingsConfiguration';
import SubscriptionLayout from '../Settings/Subscription/SubscriptionLayout';
import AttributesGroups from '../Settings/Attributes/AttributesGroups';
import ManageLeftNavBarConfiguration from '../Settings/GlobalSettings/LeftNavBar/NavbarConfiguration';

const APPURL = require('../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;

let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

const AllRoutes = () => {
  const [root_state, setRoot_state] = useState({
    loggedIn: '',
    reload: false,
    dataLoad: false,
    isVisible: false,
    onboardingCompleted: false,
    inviteMember: false,
    moveToLandingPage: false,
    subscribed: false,
    rootViewComponent: itsMobileView ? 1 : 3,
  });

  // states
  const {
    loggedIn,
    reload,
    dataLoad,
    isVisible,
    onboardingCompleted,
    inviteMember,
    moveToLandingPage,
    subscribed,
    rootViewComponent,
  } = root_state;

  // effects
  useEffect(() => {
    const fetch_data = async () => {
      const responseJson = await networkService.networkCall(
        APPURL.URLPaths.clients,
        'get',
        '',
        AppConstants.BearerForTantantsAndUserDetail,
      );
      if (responseJson['status'] == true) {
        var configs = getSelectedTenantDetails(responseJson['data']['tenants']);
        Linking.getInitialURL().then(url => {
          const {hostname} = new URL(url);
          AppConstants.appMainURL = hostname;
          let productAccess = configs[0]['product_access'];
          // console.log('productAccess', productAccess)
          let rsp = getProductAccess(productAccess, url);
          // this.state.moveToLandingPage = rsp;
        });
        saveAdminData('adminKey', configs[0]['admin_key'], 2);
        saveUserData('id', configs[0]['id'], 4);
        getCurrentUser(resp => {
          loadConfigApi();
        });
      }
    };
    fetch_data();
    //
    AppConstants.rootViewComponent = itsMobileView ? 1 : 3;

    //
    setRoot_state(prevState => ({
      ...prevState,
      dataLoad: true,
    }));
    //
    clearStorage('modules');
  }, [0]);

  //
  const initializeMixPanel = () => {
    mixpanel.init(AppConstants.mixpanelID, {debug: false});
    setupUserPropertyOnMixpanel();
  };

  const setupLogrocket = () => {
    LogRocket.init(AppConstants.logRocketKey);
    LogRocket.identify(AppConstants.userFullName, {
      name: AppConstants.userFullName,
      // email: AppConstants.userEmail ,
    });
    setupLogRocketReact(LogRocket);
  };

  //
  const loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=onboarding,general',
      'get',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      AppConstants.siteURL = configs['site_url'] ?? '';
      getLoggedInUserDetail();
      clientTanantsApi();
      clientAllDomains();
      loadSuperAdminSetupConfigApi();
      loadAllConfigGroupApi();
      // this.setState({
      //   onboardingCompleted: configs['onboarding_completed'] || false,
      // });
      AppConstants.appType = configs['sub_type'] == 1 ? false : true;
      AppConstants.moduleType = configs['type'] || 1;

      getLoggedInUserDetail();
      // this.setState({reload: true });
    } else {
    }
    setRoot_state(prevState => ({
      ...prevState,
      dataLoad: true,
    }));
  };

  //Load all configs group wise
  const loadConfigsByGroup = async () => {
    const responseJson = await networkService.networkCall(
      `${APPURL.URLPaths.config_list_group}?key_group=${AppConstants.all_groups_name}`,
      'get',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      AppConstants.ALL_CONFIGS = configs['site_url'] ?? '';

      // this.setState({reload: true });
    }
    setRoot_state(prevState => ({
      ...prevState,
      dataLoad: true,
    }));
  };

  //
  const loadAllConfigGroupApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.group_configs_list +
        `general,onboarding,accounts,listings,integrations,payments,draft_styles,seo,styles,social,address,orders,shipping,pages`,
      'get',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      set_root_configs({all_configs: configs});
    }
    setRoot_state(prevState => ({
      ...prevState,
      dataLoad: true,
    }));
  };

  //
  const loadSuperAdminSetupConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.group_configs_list + `superadmin`,
      'get',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      CONFIG_CONSTANT.SUPERADMIN = configs?.superadmin ?? {};
    }
    setRoot_state(prevState => ({
      ...prevState,
      dataLoad: true,
    }));
  };

  //
  const clientTanantsApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.clients,
      'get',
      '',
      AppConstants.BearerForTantantsAndUserDetail,
    );
    if (responseJson['status'] == true) {
      var configs = getSelectedTenantDetails(responseJson['data']['tenants']);
      AppConstants.tenantName = `${configs[0]['id']}`;
    }
  };

  //
  const clientAllDomains = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.domains,
      'get',
      '',
      AppConstants.BearerForTantantsAndUserDetail,
    );
    if (responseJson['status'] == true) {
      var domains = responseJson['data']['tenant_domains'];
      var primary_domain = domains.filter(d => d.primary);
      AppConstants.domainKey = primary_domain?.[0]?.['domain']
        ? `https://${primary_domain?.[0]?.['domain']}`
        : '';
      AppConstants.all_domains = domains ?? [];
    }
  };

  //
  const getLoggedInUserDetail = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.skuaUser,
      'get',
      '',
      '',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['user'];
      AppConstants.userId = configs['id'];
      AppConstants.userFullName = configs['name'] || '';
      AppConstants.userEmail = configs['email'] || '';
      AppConstants.userProfilePic = configs['profile_pic'] || '';
      getTenantApi();
      setupLogrocket();
      getUserRoleModule();
      getsubscriptionsPlans();
      initializeMixPanel();
    } else {
      setRoot_state(prevState => ({
        ...prevState,
        dataLoad: true,
      }));
    }
  };

  //
  const getUserRoleModule = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.userRole + `/${AppConstants.userId}/modules`,
      'get',
      '',
      '',
    );
    if (responseJson['status'] == true) {
      let modules = responseJson['data']['modules'];
      // console.log('modules',modules);
      if (AppConstants.isDebug) {
        setRoot_state(prevState => ({
          ...prevState,
          reload: true,
          isVisible: true,
        }));
      }
      saveUserData('modules', modules, 5);
    } else {
      if (AppConstants.isDebug) {
        setRoot_state(prevState => ({
          ...prevState,
          reload: true,
          isVisible: true,
        }));
      }
    }
  };

  //
  const getsubscriptionsPlans = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.subscriptionsPlans,
      'get',
    );
    // AppConstants.restrictedFeatureArray = ["social_auth", "languages","currencies","attributes","bulk_edit_categories","bulk_edit_listings"]
    if (responseJson['status'] == true) {
      // console.log('responseJson', responseJson);
      let subData = responseJson['data'];
      let plans = subData['plans'];
      let oldPlan = subData['old_plan'];
      let unpaid = subData['unpaid_invoices'];
      if (unpaid > 0) {
        AppConstants.subscribed = false;
      } else {
        if (oldPlan != null) {
          checkingSubscription(oldPlan);
        } else {
          for (let plan of plans) {
            checkingSubscription(plan);
          }
        }
      }
      setRoot_state(prevState => ({
        ...prevState,
        reload: true,
        isVisible: true,
      }));
    } else {
      setRoot_state(prevState => ({
        ...prevState,
        reload: true,
        isVisible: true,
      }));
    }
  };

  //
  const checkingSubscription = plan => {
    if (plan['subscribed']) {
      AppConstants.restrictedFeatureArray = plan['restricted_features'];
      // AppConstants.restrictedFeatureArray = [ "social_auth", "languages","currencies","attributes"]
    }
    if (plan['name'] != 'community_monthly') {
      if (plan['subscribed'] && !plan['expired']) {
        AppConstants.subscribed = true;
      }
    } else {
      if (plan['subscribed']) {
        AppConstants.subscribed = true;
      }
    }
  };

  //
  const getTenantApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.clients,
      'get',
      '',
      AppConstants.BearerForTantantsAndUserDetail,
    );
    if (responseJson['status'] == true) {
      var configs = getSelectedTenantDetails(responseJson['data']['tenants']);
      AppConstants.tenantName = `${configs[0]['id']}`;
    }
    setRoot_state(prevState => ({
      ...prevState,
      dataLoad: true,
    }));
  };

  const ChangeRootView = componentNumber => {
    setRoot_state(prevState => ({
      ...prevState,
      rootViewComponent: componentNumber,
    }));
    AppConstants.rootViewComponent = componentNumber;
  };

  let router = createBrowserRouter([
    {
      path: '/',
      element: <MainRootView />,
      // errorElement: <Error404 />,
      children: [
        {
          path: 'dashboard',
          element: <DashboardScreen2 />,
        },
        {
          path: 'reports',
          element: <Reports />,
        },
        {
          path: 'orders',
          element: <Orders />,
        },
        // {
        //   path: 'order/:orderID',
        //   element: <OrderDetails />,
        // },
        {
          path: 'orders/:orderID',
          element: <OrderDetails2 />,
        },
        {
          path: 'transaction',
          element: <Transaction />,
        },
        {
          path: 'app-customization',
          element: <MobileAppConfig />,
        },
        {
          path: 'app-integration',
          element: <NativeIntegrations />,
        },

        // {
        //   path: 'seo',
        //   element: <SEOSetting />,
        // },
        {
          path: 'strings-customisation',
          element: <AppTranslation />,
        },
        {
          path: 'communications',
          element: <Notifications />,
        },
        {
          path: 'accounts',
          element: AppConstants.appType ? <Error /> : <AccountLists />,
        },
        {
          path: 'account/:accountID',
          element: AppConstants.appType ? <Error /> : <AccountDetail2 />,
        },
        {
          path: 'account-category',
          element: AppConstants.appType ? (
            <Error />
          ) : (
            <CategoryList type={'Accounts'} />
          ),
        },
        {
          path: 'account-attributes',
          element: AppConstants.appType ? (
            <Error />
          ) : (
            <AttributesList type={'Accounts'} />
          ),
        },
        {
          path: 'account-configs',
          element: AppConstants.appType ? <Error /> : <AccountSetting />,
        },
        {
          path: 'listings',
          element: <MainListings />,
        },
        {
          path: 'listing/:listingID',
          element: <ListingDetail2 />,
        },
        {
          path: 'edit-listing/:listingID',
          element: <AddListings isEdit={true} />,
        },
        {
          path: 'add-listing',
          element: AppConstants.appType ? <AddListings /> : <Error />,
        },
        {
          path: 'listing-category',
          element: <CategoryList2 type={'Listings'} />,
          children: [
            {
              path: 'translation',
              element: <CategoryDescriptionTranslate />,
            },
          ],
        },
        {
          path: 'add-listing-category',
          element: <AddCategory2 type={'Listings'} />,
        },
        {
          path: 'listing-attributes',
          element: <AttributesList type={'Listings'} />,
        },
        {
          path: 'attribute-groups',
          element: <AttributesGroups type={'Listings'} />,
        },
        {
          path: 'add-attribute',
          element: <AddAttributes2 />,
        },
        {
          path: 'edit-attribute/:attributeID',
          element: <AddAttributes2 isEdit={true} />,
        },

        //
        {
          path: 'cart-attributes',
          element: <AttributesList type={'cart_attributes'} />,
        },
        {
          path: 'attribute-groups',
          element: <AttributesGroups type={'cart_attributes'} />,
        },

        //
        {
          path: 'listing-configs',
          element: <ListingsConfiguration />,
        },
        {
          path: 'listing-variants',
          element: <ListingVariantType type={'Listings'} />,
        },
        {
          path: 'collections',
          element: <Collections />,
        },
        {
          path: 'create-collection',
          element: <CreateCollection2 />,
        },
        {
          path: 'edit-collection/:collectionID',
          element: <CreateCollection2 isEdit={true} />,
        },
        {
          path: 'promotions',
          element: <PromoBanners />,
        },
        {
          path: 'add-promotion',
          element: <AddPromoBanner />,
        },
        {
          path: 'edit-promotion/:promoID',
          element: <AddPromoBanner isEdit={true} />,
        },
        {
          path: 'coupon',
          element: <Coupons />,
        },
        {
          path: 'add-coupon',
          element: <AddCoupon />,
        },
        {
          path: 'edit-coupon/:couponID',
          element: <AddCoupon isEdit={true} />,
        },
        {
          path: 'users',
          element: <Users />,
        },
        {
          path: 'user/:userID',
          element: <UserDetail2 />,
        },
        {
          path: 'user-attributes',
          element: <AttributesList type={'Users'} />,
        },
        {
          path: 'media',
          element: <MediaManagers />,
        },
        {
          path: 'review-ratings',
          element: <ReviewRatings />,
        },
        {
          path: 'reported-listings',
          element: <ReportedListings />,
        },
        // {
        //   path: 'reported-accounts',
        //   element: <ReportedAccounts />,
        // },
        {
          path: 'settings',
          element: <Setting />,
        },
        {
          path: 'delivery',
          element: <ShippingMethod />,
        },
        {
          path: 'payments',
          element: <PaymentMethod />,
        },
        {
          path: 'commission',
          element: <Commision />,
        },
        {
          path: 'subscription',
          element: <SubscriptionLayout />,
        },

        {
          path: 'taxes',
          element: <Taxes />,
        },
        {
          path: 'special-fees',
          element: <SpecialFees />,
        },
        {
          path: 'languages',
          element: <LanguageLists />,
        },
        {
          path: 'currency',
          element: <Currencies />,
        },
        {
          path: 'countries',
          element: <AppCountry />,
        },
        {
          path: 'api',
          element: <Developers />,
        },
        // {
        //   path: 'auth',
        //   element: <Auth />,
        // },
        {
          path: 'checkouts',
          element: <Checkouts />,
        },
        {
          path: 'manage_lfn',
          element: <ManageLeftNavBarConfiguration />,
        },
        {
          path: 'test',
          element: <ParentTest />,
          children: [{path: 'test-c1', element: <Test />}],
        },
      ],
    },
  ]);

  return reload ? (
    <RouterProvider router={router} />
  ) : (
    <SafeAreaView style={styles.container}>
      <Appload enable={isVisible} />
    </SafeAreaView>
  );
};

export default AllRoutes;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: AppColor.AppLightGreen,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
