import {StyleSheet, Dimensions} from 'react-native';
import AppConstants from '../Constants/AppConstants';
import colors from './../HelperClasses/AppColor';
const windowheight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;
let perHeight = (windowheight * 20) / 100;
let fixedWidth = AppConstants.mobileMaxWidth;;

let itsMobileView = windowWidth < fixedWidth ? true : false;
const commonWidth = 6.18;
let constantWidth =
  windowWidth < fixedWidth ? windowWidth / 3 : windowWidth / 10.4;
let firstWidth = windowWidth / 4.2;

export default StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
    flex: 1,
  },
  // Orders
  tableViewTitleViewStyle: {
    padding: 0,
    width: windowWidth < fixedWidth ? 130 : windowWidth / commonWidth,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'rgb(250, 251, 251)',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: windowWidth < fixedWidth ? 130 : windowWidth / commonWidth,
    justifyContent: 'flex-start',
    padding: 10,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },

  //   Order details

  subViewStyle: {
    margin: 5,
    borderLeftWidth: 1,
    borderLeftColor: colors.Lightgray,
    paddingLeft: 10,
    paddingBottom: 10,
    width: '30%',
  },
  subConatinerView: {
    margin: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    marginTop: 20,
  },
  order_details_tableViewTitleViewStyle: {
    paddingLeft: 10,
    width: itsMobileView ? 130 : '16.5%',
    height: 40,
  },
  order_details_columnTitleViewStyle: {
    width: itsMobileView ? 130 : '16.5%',
    paddingLeft: 10,
  },
  lastViewStyle: {
    padding: 16,
    flexDirection: 'column',
    width: 300,
  },
  navigationViewStyle: {
    margin: 10,
    flexDirection: itsMobileView ? 'column' : 'row',
    borderRadius: 5,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    padding: itsMobileView ? 10 : 0,
    alignItems: 'center',
    height: itsMobileView ? 130 : 60,
    marginTop: 2,
  },
  headerContainer: {
    flexDirection: itsMobileView ? 'column' : 'row',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
  headerListViewcontainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  statusItemViewStyle: {
    margin: 5,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  datePickerContainerStyle: {
    backgroundColor: colors.BackgroundBlueColor,
    padding: 20,
    borderRadius: 5,
    position: 'absolute',
    zIndex: 10,
    paddingLeft: 30,
    paddingRight: 30,
  },

  //   Main Listing

  headerViewstyle: {
    marginLeft: 10,
    flexDirection: itsMobileView ? 'column' : 'row',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    zIndex: 777,
    justifyContent: 'space-between',
    paddingRight: 10,
  },
  listSubView: {
    backgroundColor: 'white',
    margin: 16,
    zIndex: 1,
  },

  searchViewStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  main_listing_tableViewTitleViewStyle: {
    padding: 0,
    width: itsMobileView ? 140 : constantWidth,
    height: 40,
  },
  firstTableViewTitleViewStyle: {
    padding: 0,
    width: itsMobileView ? 140 : firstWidth,
    height: 40,
  },
  firstCellViewStyle: {
    width: itsMobileView ? 140 : firstWidth,
    padding: 10,
    paddingLeft: 0,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    justifyContent: 'left',
  },
  main_listing_columnTitleViewStyle: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    padding: 2,
    width: itsMobileView ? 140 : constantWidth,
  },
  approveRejectViewStyle: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    alignSelf: 'center',
    paddingLeft: 5,
  },
  configurationBarViewStyle: {
    // alignItems: 'center',
    // flexDirection: 'row',
    // marginRight: 10,
    // flexWrap:'wrap',
    // width:windowWidth/3,
    marginRight: 10,
    flexWrap: 'wrap',
    width: itsMobileView ? windowWidth / 1.1 : windowWidth / 3,
    alignItems: 'flex-start',
    justifyContent: itsMobileView ? 'flex-start' : 'center',
    flexDirection: 'row',
  },

  //   Listing details
  headerView: {
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: 'white',
    flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: windowWidth < fixedWidth ? 110 : 60,
    alignItems: windowWidth < fixedWidth ? 'flex-start' : 'center',
    justifyContent: windowWidth < fixedWidth ? 'flex-start' : 'space-between',
    paddingLeft: windowWidth < fixedWidth ? 10 : 0,
  },
  listing_detail_tableViewTitleViewStyle: {
    padding: 0,
    width: 170,
    height: 40,
  },

  listing_detail_columnTitleViewStyle: {
    width: 170,
    justifyContent: 'flex-start',
    padding: 2,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  attributeListViewStyle: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: colors.Lightgray,
  },
  fullImageContainStyle: {
    width: '98%',
    height: windowheight,
    position: 'absolute',
    zIndex: 99203,
    backgroundColor: colors.transparentBlackColor,
    alignSelf: 'center',
  },

  // Add listing

  mainView: {
    margin: itsMobileView ? 1 : 10,
    backgroundColor: 'white',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
    padding: 5,
    paddingTop: 0,
    zIndex: 9,
    marginLeft: 5,
  },
  leftMainView: {
    margin: itsMobileView ? 1 : 10,
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    padding: 5,
    paddingTop: 0,
    flex: 1.6,
    zIndex: 909,
  },
  firstViewStyle: {
    marginTop: 10,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: '100%',
    zIndex: 111,
    padding: 2,
  },
  add_listing_subViewStyle: {
    flexDirection: 'column',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1,
  },
  dropDownViewStyle: {
    marginTop: 16,
    borderWidth: 0,
    borderColor: colors.SimonGray,
    height: 40,
    borderRadius: 5,
    flexDirection: 'column',
    alignItems: 'center',
    borderColor: colors.AppGreenBorder,
    width: '100%',
  },
  selectionViewStyle: {
    marginTop: 16,
    borderWidth: 0,
    borderColor: colors.SimonGray,
    borderRadius: 5,
    flexDirection: 'column',
    alignItems: 'center',
    borderColor: colors.AppGreenBorder,
    width: '100%',
  },
  dropdownLabelStyle: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  imagePickerPlaceholderStyle: {
    width: 100,
    height: 100,
    justifyContent: 'center',
    borderColor: colors.SimonGray,
    borderStyle: 'dashed',
    borderWidth: 2,
    borderRadius: 10,
  },
  imageSelectedStyle: {
    width: 100,
    height: 100,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 20,
  },
  SelectedImageStyle: {
    height: 100,
    width: 100,
    marginTop: 16,
    borderRadius: 10,
  },
  deleteViewStyle: {
    height: 20,
    width: 20,
    top: -100,
    left: 106,
  },
  deleteImageStyle: {
    height: 20,
    width: 20,
    borderColor: 'black',
    borderRadius: 10,
    borderWidth: 1,
    backgroundColor: 'white',
  },
  mapViewContainerStyle: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 9922,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.transparentBlackColor,
  },
  closeBtnViewStyle: {
    alignSelf: 'flex-end',
    marginTop: 16,
    marginRight: 16,
  },
  weekendViewStyle: {
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    flexDirection: 'row',
  },
  weekdaysStyle: {
    margin: 10,
    borderColor: colors.Appgreen,
    borderWidth: 1,
    padding: 7,
    borderRadius: 5,
  },
  selectedWeekdaysStyle: {
    margin: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    padding: 7,
    borderRadius: 5,
  },
  marginStyleView: {
    margin: 16,
    marginTop: 0,
    marginBottom: 0,
  },

  // Report Style

  mainViewStyle: {
    flexDirection: 'column',
    flex: 1,
    backgroundColor: 'white',
  },
  report_mainView: {
    paddingLeft: 20,
    paddingRight: 20,
    margin: 10,
    marginTop: 0,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    height: itsMobileView ? '100%' : windowheight - 115,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    alignItems: 'center',
  },
  containerView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    backgroundColor: colors.AppWhite,
    width: itsMobileView ? windowWidth : windowWidth < fixedWidth,
  },
  settingViewsStyle: {
    margin: 20,
    backgroundColor: colors.AppWhite,
    flexDirection: 'row',
    borderRadius: 10,
    height: 80,
    width: itsMobileView ? windowWidth / 2 - 50 : 200,
    alignItems: 'center',
    justifyContent:'center',
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 10,
  },
  report_subViewStyle: {
    flexDirection: 'column',
    flex: 1,
    paddingLeft: 10,
  },
  titleStyle: {
    fontSize: 12,
    fontWeight: '400',
    textAlign: 'left',
    color: colors.Appgray,
  },
  subTitleStyle: {
    fontSize: 16,
    textAlign: 'left',
    fontWeight: '500',
    marginTop: 10,
  },
  iconImageViewStyle: {
    height: 40,
    width: 40,
    marginLeft: 16,
  },
  charViewStyle: {
    width: itsMobileView ? windowWidth / 1.3 : windowWidth / 2.6,
    height: 320,
    borderRadius: 10,
    padding: 10,
    shadowColor: colors.Appgray,
    justifyContent: 'flex-start',
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
    marginLeft: 10,
  },
  topListingViewStyle: {
    width: itsMobileView ? windowWidth / 1.3 : windowWidth / 2.5,
    backgroundColor:colors.AppWhite
  },
  listingHeader:{
    height: 40,  
    backgroundColor: colors.AppLightGreen
    ,borderTopLeftRadius: 10,
    borderTopRightRadius: 10, 
  }
});
