import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  FlatList,
  Dimensions,
  Platform,
  SafeAreaView,
  Alert,
} from 'react-native';
import colors from '../HelperClasses/AppColor';

import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';

// import Language from '../Settings/Languages/Languages';

import networkService from '../Network/NetworkManager';

import iconGlobal from './../assets/global.png';
import mobileIcon from './../assets/mobileConfigIcon.png';
import notiIcon from './../assets/notificationIcon.png';
import aacountsIcon from './../assets/accounts.png';
import listingsIcon from './../assets/listings.png';
import nativeintegrationIcon from './../assets/nativeintegration.png';
import commissionsIcon from './../assets/commissions.png';
import multiCurrencyIcon from './../assets/multiCurrency.png';
import apiIcon from './../assets/api.png';
import subscriptionsIcon from './../assets/subscriptions.png';
import specialFeesIcon from './../assets/specialFees.png';
import languagecon from './../assets/language.png';
import paymentMethodIcon from './../assets/paymentMethod.png';
import shippingMethodIcon from './../assets/shippingMethod.png';
import appTranslateIcon from './../assets/appTranslate.png';
import seoIcon from './../assets/seoIcon.png';
import openLinkIcon from './../assets/openLinkIcon.png';

import AppConstant from '../Constants/AppConstants';
import WebConfigs from './WebConfig/WebConfigs';
import {ToastContainer} from 'react-toastify';

import {checkUserRoleModule} from '../HelperClasses/SingletonClass';
import {MODULEKEYS} from '../Models/Enum';
import {AppToast} from '../HelperClasses/AppToast';
import {addMixpanelEvent, MixpanelEvent} from '../Models/MixPannelEnum';
import SettingStyleSheet from '../StyleSheets/SettingStyleSheet';
import Taxes from './Taxes/Taxes';
import ConstantArray from '../Constants/ConstantArray';
import Auth from './Auth/Auth';
import {withRouter} from '../wrappers/withRouter';
import {ICONS} from '../Constants/Icons';

const APPURL = require('../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let fixedWidth = 800;

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabIndex: 0,
      updateUI: false,
      checkoutRead: AppConstant.defaultRead,
      permissionArray: [],
      dataLoad: false,
    };
    this.renderSettingSubView = this.renderSettingSubView.bind(this);
    this.renderSettingView = this.renderSettingView.bind(this);
  }
  componentDidMount() {
    var a = 0;
    while (a < 22) {
      a++;
      this.state.permissionArray.push(AppConstant.defaultRead);
    }
    this.checkModule();
    this.loadConfigApi();
  }
  //MARK:-  APIs Method 
  loadConfigApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config + '?key_group=general',
      'get',
    );
    if (responseJson['status'] == true) {
      var configs = responseJson['data']['configs'];
      if (configs['auth_type'] == 2 || configs['auth_type'] == 3) {
        // titleArray.push('App Country')
      }
    }
    this.setState({dataLoad: true});
  };
  alertView(title) {
    if (Platform.OS === 'web') {
      alert(title);
    } else {
      Alert.alert(title);
    }
  }
  //MARK:-  Buttons Action Method 
  submitButtonAction() {}
  didSelectSetting = (id, name, permission, item) => {
    if (id == 22) {
      this.props.navigate(item.href);
    }
    if (permission) {
      if (id == 7) {
        addMixpanelEvent(MixpanelEvent.setting, {
          action: MixpanelEvent.webIntegration,
        });
        window.open(item.href, '_blank');
      } else if (id == 2) {
        addMixpanelEvent(MixpanelEvent.setting, {
          action: MixpanelEvent.webCustomisation,
        });
        window.open(item.href, '_blank');
      } else {
        addMixpanelEvent(MixpanelEvent.setting, {navigate: name});
        this.props.navigate(item.href);
      }
    } else {
      AppToast(`You don't have access of ${name}`);
    }
  };
  backBtnHandler() {
    this.setState({selectedTabIndex: 0});
  }
  /*  UI   */
  renderSettingView() {
    var views = [];
    var titleAry = [...SettingArray];
    if (AppConstant.appType) {
      if (titleAry[0]['item'][4]) {
        if (
          titleAry[0]['item'][4]['title'] ==
          ConstantArray.SideAppSubMenuArray[2]
        ) {
          titleAry[0]['item'].splice(4, 1);
        }
      }
      if (titleAry[2]['item'][0]['title'] == 'Accounts') {
        titleAry[2]['item'].splice(0, 1);
      }
      if (titleAry[3]['item'][2]['title'] == 'Commissions') {
        titleAry[3]['item'].splice(2, 1);
      }
      if (titleAry[4]['item'][3] == undefined) {
        titleAry[4]['item'].push(checkoutObject);
      }
    }
    var id = 0;
    for (let a = 0; a < titleAry.length; a++) {
      let objc = titleAry[a];
      var subViews = [];
      for (let b = 0; b < objc['item'].length; b++) {
        let item = objc['item'][b];
        let pp = this.state.permissionArray[item['id']];
        var link = '';
        if (item['link']) {
          link = item['link'];
        }
        subViews.push(
          <TouchableOpacity
            style={styles.settingViewsStyle}
            onPress={() =>
              this.didSelectSetting(item['id'], item['title'], pp, item)
            }>
            <Image
              style={styles.iconImageViewStyle}
              resizeMode="contain"
              source={item['image']}
            />
            <View style={{paddingLeft: 10}}>
              <Text style={SettingStyleSheet.titleStyle}>{item['title']}</Text>
              <Text style={SettingStyleSheet.settingSubTitleStyle}>
                {item['subTitle']}
              </Text>
            </View>
            <Image
              style={{
                height: 16,
                width: 16,
                tintColor: colors.AppNewGreen,
                marginLeft: 5,
                marginTop: -2,
              }}
              source={link}
            />
          </TouchableOpacity>,
        );
      }
      views.push(
        <View>
          <Text style={SettingStyleSheet.settingTitleText}>{objc['name']}</Text>
          <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
            {subViews}
          </View>
        </View>,
      );
    }
    return views;
  }
  renderMainView = () => {
    return (
      <View style={styles.mainView}>
        <ScrollView style={{width: '100%'}}>
          <View style={styles.containerView}>{this.renderSettingView()}</View>
        </ScrollView>
      </View>
    );
  };
  renderSettingSubView() {
    return <this.renderMainView />;
  }
  render() {
    return (
      <SafeAreaView style={styles.Container}>
        <View style={{flexDirection: 'column', height: '100%'}}>
          <ToastContainer />
          <this.renderSettingSubView />
        </View>
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
  checkModule = () => {
    // checkUserRoleModule(MODULEKEYS.GLOBAL, res => {
    //   this.state.permissionArray[0] = res[0];
    // })
    checkUserRoleModule(MODULEKEYS.APPCONFIG, res => {
      this.state.permissionArray[1] = res[0];
    });
    checkUserRoleModule(MODULEKEYS.WEBCONFIG, res => {
      this.state.permissionArray[2] = res[0];
      this.setState({updateUI: !this.state.updateUI});
    });
    checkUserRoleModule(MODULEKEYS.NOTIFICATIONS, res => {
      this.state.permissionArray[3] = res[0];
    });
    checkUserRoleModule(MODULEKEYS.SEO, res => {
      this.state.permissionArray[4] = res[0];
    });
    checkUserRoleModule(MODULEKEYS.TRANSLATIONMANAGE, res => {
      this.state.permissionArray[5] = res[0];
    });
    checkUserRoleModule(MODULEKEYS.NATIVEINTEGRATIONS, res => {
      this.state.permissionArray[6] = res[0];
    });
    checkUserRoleModule(MODULEKEYS.ACCOUNTS, res => {
      this.state.permissionArray[8] = res[0];
    });
    checkUserRoleModule(MODULEKEYS.LISTIINGS, res => {
      this.state.permissionArray[9] = res[0];
    });
    checkUserRoleModule(MODULEKEYS.SHIPPINGMETHOD, res => {
      this.state.permissionArray[10] = res[0];
    });
    checkUserRoleModule(MODULEKEYS.PAYMENTMETHOD, res => {
      this.state.permissionArray[11] = res[0];
    });
    checkUserRoleModule(MODULEKEYS.COMMISSIONS, res => {
      this.state.permissionArray[12] = res[0];
    });
    checkUserRoleModule(MODULEKEYS.SUBSCRIPTION, res => {
      this.state.permissionArray[13] = res[0];
    });
    checkUserRoleModule(MODULEKEYS.SPECIALFEES, res => {
      this.state.permissionArray[14] = res[0];
    });
    checkUserRoleModule(MODULEKEYS.LANGUAGE, res => {
      this.state.permissionArray[15] = res[0];
    });
    checkUserRoleModule(MODULEKEYS.CURRENCY, res => {
      this.state.permissionArray[16] = res[0];
    });
    checkUserRoleModule(MODULEKEYS.COUNTRY, res => {
      this.state.permissionArray[17] = res[0];
    });
    checkUserRoleModule(MODULEKEYS.TAXVIEW, res => {
      this.state.permissionArray[20] = res[0];
    });
    checkUserRoleModule(MODULEKEYS.APIVIEW, res => {
      this.state.permissionArray[18] = res[0];
    });
    // checkUserRoleModule(MODULEKEYS.WEBCONFIG, res => {
    //   this.state.permissionArray[16] = res[0];
    //   this.setState({ updateUI: !this.state.updateUI})
    // })
    checkUserRoleModule(MODULEKEYS.CHECKOUT, res => {
      this.state.permissionArray[19] = res[0];
      this.setState({checkoutRead: res[0]});
    });

    this.setState({updateUI: !this.state.updateUI});
  };
}

export default withRouter(Settings);

const styles = StyleSheet.create({
  Container: {
    height: '100%',
    backgroundColor: colors.AppLightGreen,
  },
  mainView: {
    padding: 20,
    margin: 10,
    marginTop: 10,
    paddingLeft: 10,
    borderRadius: 10,
    height: windowWidth < fixedWidth ? windowHeight - 80 : windowHeight - 80,
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    alignItems: 'flex-start',
    paddingBottom: 0,
    backgroundColor: colors.AppWhite,
  },
  containerView: {
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width:
      windowWidth < fixedWidth ? windowWidth - 10 : windowWidth < fixedWidth,
    flexDirection: 'column',
  },
  settingViewsStyle: {
    margin: 16,
    backgroundColor: 'white',
    flexDirection: 'row',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    minHeight: 50,
    // width: 180,
    alignItems: 'center',
    // justifyContent: 'center',
    padding: 5,
    paddingRight: 10,
  },
  iconImageViewStyle: {
    height: 30,
    width: 30,
    // marginTop: 16,
  },
});

const SettingArray = [
  {
    name: 'Customisation',
    description: '',
    item: [
      {
        title: 'App Customisation',
        subTitle: '',
        id: 1,
        image: mobileIcon,
        href: '/app-customization',
      },
      {
        title: 'Web Customisation',
        subTitle: '',
        id: 2,
        image: iconGlobal,
        link: openLinkIcon,
        href: 'https://tradly.app/editor',
      },
      {
        title: 'Communications',
        subTitle: '',
        id: 3,
        image: notiIcon,
        href: '/communications',
      },
      // {
      //   title: 'SEO',
      //   subTitle: '',
      //   id: 4,
      //   image: seoIcon,
      //   href: '/seo',
      // },
      {
        title: 'Strings Customisation',
        subTitle: '',
        id: 5,
        image: appTranslateIcon,
        href: '/strings-customisation',
      },
    ],
  },
  {
    name: 'Integrations',
    description: '',
    item: [
      {
        title: 'App Integrations',
        subTitle: '',
        id: 6,
        image: nativeintegrationIcon,
        href: '/app-integration',
      },
      {
        title: 'Web Integrations',
        subTitle: '',
        id: 7,
        image: mobileIcon,
        link: openLinkIcon,
        href: 'https://tradly.app/workspace/integrations',
      },
    ],
  },
  {
    name: 'Platform Setup',
    description: '',
    item: [
      {
        title: 'Accounts',
        subTitle: 'Example: Store',
        id: 8,
        image: aacountsIcon,
        href: '/account-configs',
      },
      {
        title: 'Listings',
        subTitle: 'Example: Products, Events',
        id: 9,
        image: listingsIcon,
        href: '/listing-configs',
      },
      {
        title: 'Checkout & Cart Attributes',
        id: 22,
        image: ICONS.seoIcon,
        href: '/cart-attributes',
        is_permission_needed: false,
      },
    ],
  },
  {
    name: 'Business Operations',
    description: '',
    item: [
      {
        title: 'Service / Shipping',
        subTitle: '',
        id: 10,
        image: shippingMethodIcon,
        href: '/delivery',
      },
      {
        title: 'Payments',
        subTitle: '',
        id: 11,
        image: paymentMethodIcon,
        href: '/payments',
      },
      {
        title: 'Commissions',
        subTitle: '',
        id: 12,
        image: commissionsIcon,
        href: '/commission',
      },
      {
        title: 'Subscription',
        subTitle: '',
        id: 13,
        image: subscriptionsIcon,
        href: '/subscription',
      },
      {
        title: 'Taxes',
        subTitle: '',
        id: 20,
        image: commissionsIcon,
        href: '/taxes',
      },
      {
        title: 'Special Fees',
        subTitle: '',
        id: 14,
        image: specialFeesIcon,
        href: '/special-fees',
      },
    ],
  },
  {
    name: 'System Setup',
    description: '',
    item: [
      {
        title: 'Languages',
        subTitle: '',
        id: 15,
        image: languagecon,
        href: '/languages',
      },
      {
        title: 'Currency',
        subTitle: '',
        id: 16,
        image: multiCurrencyIcon,
        href: '/currency',
      },
      {
        title: 'Countries',
        subTitle: '',
        id: 17,
        image: mobileIcon,
        href: '/countries',
      },
      {
        title: 'API',
        subTitle: '',
        id: 18,
        image: apiIcon,
        href: '/api',
      },

      {
        title: 'Manage Features',
        subTitle: '',
        id: 18,
        image: apiIcon,
        href: '/manage_lfn',
      },
      // {
      //   title: 'Auth',
      //   subTitle: '',
      //   id: 21,
      //   image: mobileIcon,
      //   href: '/auth',
      // },
    ],
  },
];
const checkoutObject = {
  title: 'Checkout',
  subTitle: '',
  id: 18,
  image: iconGlobal,
  href: '/checkout',
};
