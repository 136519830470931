import AppConstants from '../Constants/AppConstants';
import colors from '../HelperClasses/AppColor';
import {
  b1,
  b2,
  button1,
  button2,
  h2,
  h3,
  h4,
  p1,
  p2,
  t1,
  t2,
} from './TextTitleStyleSheet';
// 'use strict';
var React = require('react-native');
var {StyleSheet, Dimensions, I18nManager} = React;
const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;
const alignment = I18nManager.isRTL ? 'right' : 'left';
const direction = I18nManager.isRTL ? 'rtl' : 'ltr';
const rotationDeg = I18nManager.isRTL ? '180deg' : '0deg';

export default StyleSheet.create({
  titleTextStyle: {
    ...h2,
    margin: 0,
    marginLeft: 0,
    color: colors.AppTitleBlack,
    textAlign: alignment,
  },
  listTitleText: {
    ...h2,
    margin: 16,
    color: colors.AppTitleBlack,
  },
  listTitleText2: {
    ...h2,
    color: colors.AppTitleBlack,
  },
  mainTitile: {
    margin: 20,
    ...h3,
    color: colors.AppTitleBlack,
  },
  statusTxtStyle: {
    ...b1,
    marginTop: 5,
    color: colors.Appgray,
  },
  mainTitleStyle: {
    ...t1,
    color: colors.AppNewGreen,
    textAlign: 'left',
  },
  ratingDescriptionTextStyle: {
    ...b1,
    textAlign: 'left',
    width: '100%',
    paddingBottom: 5,
    paddingTop: 5,
  },
  mainView: {
    margin: 10,
    marginTop: 10,
    flexDirection: 'column',
    borderRadius: 5,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    // flex: 1,
    height: windowHeight - 160,
    padding: 1,
  },
  containerMainView: {
    margin: 10,
    marginTop: 10,
    flexDirection: 'column',
    borderRadius: 5,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    // flex: 1,
    height: windowHeight - 120,
    padding: 1,
  },
  listContainerView: {
    margin: 10,
    flexDirection: 'column',
    borderRadius: 5,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    // flex: 1,
    height: windowHeight - 175,
    padding: 1,
    backgroundColor: colors.red,
  },
  mainScrollViewStyle: {
    flex: 1,
    backgroundColor: 'white',
  },

  listItemView: {
    backgroundColor: 'white',
    width: '100%',
  },
  listSubView: {
    backgroundColor: 'white',
    margin: 0,
    zIndex: 1,
    width: '100%',
  },
  headerListContainer: {
    height: 45,
    backgroundColor: colors.AppLightGreen,
    borderTopStartRadius: 5,
    borderTopEndRadius: 5,
    paddingLeft: 10,
    width: '100%',
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    // padding: 20,
  },

  dropDownView: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'rgba(230, 230, 230, 1)',
    width: 70,
    marginLeft: 10,
    height: 30,
    justifyContent: 'center',
    zIndex: 900,
  },
  dropDownViewStyle: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: 'rgba(230, 230, 230, 1)',
    width: 70,
    marginTop: 25,
    marginLeft: 10,
    zIndex: 9000,
  },
  searchTitleStyle: {
    ...t1,
    marginLeft: itsMobileView ? 0 : 16,
    textAlign: alignment,
  },
  txtFieldStyle: {
    ...t1,
    textAlign: alignment,
    paddingLeft: 5,
    marginLeft: 10,
    width: 140,
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    height: 40,
    borderRadius: 5,
    outline: 'none',
  },
  tableViewTitleViewStyle: {
    padding: 0,
  },
  tableViewTitleStyle: {
    ...h4,
    textAlign: alignment,
    paddingTop: 10,
    color: colors.AppTitleBlack,
  },
  columnViewTitleStyle: {
    ...t1,
    textAlign: alignment,
    width: '95%',
    color: colors.AppTitleBlack,
  },
  categoryColumnViewTitleStyle: {
    ...t1,
    textAlign: 'left',
    paddingLeft: 10,
    marginTop: -1,
  },
  columnActiveBtnStyle: {
    ...b2,
    textAlign: 'center',
    color: colors.Appgray,
    padding: 5,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.Appgreen,
    backgroundColor: colors.UltraLightGreen,
    alignSelf: 'center',
    overflow: 'hidden',
  },
  columnDeActiveBtnStyle: {
    ...b2,
    textAlign: 'center',
    color: colors.Appgray,
    padding: 5,
    borderRadius: 5,
    backgroundColor: colors.UltraLightRed,
    borderWidth: 1,
    borderColor: colors.AppButtonRed,
    alignSelf: 'center',
    overflow: 'hidden',
  },
  columnOrangeBtnStyle: {
    ...b2,
    textAlign: 'center',
    color: colors.Appgray,
    padding: 8,
    borderRadius: 2,
    backgroundColor: colors.AppLightOrange,
    borderWidth: 1,
    borderColor: colors.orangeColor,
    alignSelf: 'center',
    overflow: 'hidden',
  },
  showingEntriesTextStyle: {
    ...t1,
    margin: 16,
    textAlign: alignment,
  },
  nextPreviousBackViewStyle: {
    height: 40,
    flexDirection: 'row-reverse',
    alignItems: 'center',
  },
  nextPreviousViewStyle: {
    height: 40,
    alignItems: 'center',
    padding: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    marginRight: 20,
  },
  addCurrencyViewStyle: {
    width: 120,
    justifyContent: 'center',
    margin: 10,
  },
  addCurrencyTextStyle: {
    ...p2,
    textAlign: 'center',
    color: 'white',
    padding: 8,
    borderRadius: 20,
    backgroundColor: colors.AppNewGreen,
    alignSelf: 'center',
    height: 35,
    width: 250,
    marginRight: 150,
    overflow: 'hidden',
  },
  iconStyle: {
    height: 20,
    width: 20,
  },
  itemImageViewStyle: {
    width: 50,
    height: 50,
    margin: 5,
    borderRadius: 10,
    marginLeft: -1,
  },
  deleteViewStyle: {
    width: 50,
    height: 50,
    borderColor: colors.SimonGray,
    borderRadius: 5,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  gradientViewBGStyle: {
    overflow: 'hidden',
    borderRadius: 5,
    marginRight: 10,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 2},
    shadowRadius: 5,
    zIndex: 2,
  },
  grandientBGStyle: {
    marginTop: 10,
    height: 30,
    width: 100,
    borderRadius: 5,
    marginRight: 20,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.5,
    shadowOffset: {width: 0, height: 2},
    shadowRadius: 5,
  },
  bottomViewStyle: {
    height: 50,
    width: '100%',
    flexDirection: 'row-reverse',
    backgroundColor: colors.AppWhite,
  },
  nextPreviousViewStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
  },
  themeTitleTxtStyle: {
    ...t1,
    textAlign: alignment,
    color: colors.AppNewGreen,
  },
  themeTitleLinkTxtStyle: {
    ...t1,
    textAlign: alignment,
    color: colors.blueColor,
    textDecorationLine: 'underline',
  },
  gridViewStyle: {
    flexDirection: windowWidth < fixedWidth ? 'row' : 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: windowWidth < fixedWidth ? 'center' : 'flex-start',
    width:
      windowWidth < fixedWidth ? windowWidth - 10 : windowWidth < fixedWidth,
  },
  gridContentViewsStyle: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 270,
    width: 200,
  },
  gridImageViewStyle: {
    height: 200,
    width: 198,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  gridPriceViewStyle: {
    ...b2,
    textAlign: alignment,
  },
  headerViewstyle: {
    marginLeft: 10,
    marginRight: 0,
    // backgroundColor: 'white',
    flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
    // borderRadius: 10,
    // borderColor: colors.SimonGray,
    // borderWidth: 1,
    maxHeight: windowWidth < fixedWidth ? 230 : 30,
    alignItems: itsMobileView ? 'flex-start' : 'center',
    justifyContent: 'space-between',
    zIndex: 120,
    // padding: windowWidth < fixedWidth ? 5 : 0,
    marginTop: itsMobileView ? 0 : 8,
  },
  commonHeaderViewstyle: {
    marginLeft: 0,
    marginRight: 0,
    // backgroundColor: 'white',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // borderRadius: 10,
    // borderColor: colors.SimonGray,
    // borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 120,
    // padding: windowWidth < fixedWidth ? 5 : 0,
    marginTop: itsMobileView ? 0 : 8,
  },
  configurationBarViewStyle: {
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: 10,
    marginTop: 0,
  },
  statuViewStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    borderColor: colors.SimonGray,
  },
  viewIconsStyle: {
    width: 24,
    height: 24,
  },
  filterContainerViewStyle: {
    position: 'absolute',
    zIndex: 110,
    marginTop: 30,
    flexDirection: 'row-reverse',
  },
  filterViewStyle: {
    // height: 240,
    // width: 140,
    backgroundColor: 'white',
    shadowColor: colors.Appgray,
    shadowOpacity: 0.1,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 2,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    flexDirection: 'row',
    // borderTopLeftRadius:0,
  },
  orderHeaderViewstyle: {
    marginLeft: 10,
    marginRight: 10,
    // backgroundColor: 'white',
    // paddingLeft:10,
    flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
    height: windowWidth < fixedWidth ? 130 : 40,
    // alignItems: 'center',
    zIndex: 120,
  },
  selectedStatusViewStyle: {
    // marginRight:20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 5,
    borderColor: colors.Appgreen,
    borderWidth: 1,
    height: 30,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 5,
  },
  unSelectedStatusViewStyle: {
    // marginRight:20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 5,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 30,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 5,
  },
  textTitleStyle: {
    ...t1,
    textAlign: alignment,
  },
  dateTextField: {
    padding: 5,
    margin: 10,
    width: '80%',
    borderWidth: 1,
    borderColor: colors.AppGreenBorder,
    height: 30,
    borderRadius: 5,
    zIndex: 12092,
  },
  applyTxtStyle: {
    color: colors.AppNewGreen,
    ...t2,
    textAlign: alignment,
  },
  clearTxtStyle: {
    color: colors.AppRed,
    ...t2,
    textAlign: alignment,
  },

  textBoxButton: {
    position: 'absolute',
    right: -2,
    zIndex: 100,
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 2,
  },
  rightTopViewStyle: {
    flexDirection: 'row',
    alignItems: itsMobileView ? 'baseline' : 'center',
    zIndex: 132,
    marginRight: 0,
    marginTop: 5,
  },
  commonRightTopViewStyle: {
    flexDirection: 'row',
    alignItems: itsMobileView ? 'baseline' : 'center',
    zIndex: 132,
    marginRight: 0,
    marginTop: 0,
    justifyContent: 'end',
  },
  saveTxtStyle: {
    ...button1,
    color: colors.AppWhite,
    padding: 9,
    // textAlign: alignment,
  },
  perPageDropDownViewStyle: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 2,
    padding: 2,
  },
  perPagedropDownView: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: 60,
    marginLeft: 10,
    height: 35,
    alignItems: 'center',
    zIndex: 900,
    paddingLeft: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  perPageContainerViewStyle: {
    backgroundColor: 'gray',
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.SimonGray,
    width: 60,
    marginTop: -90,
    marginLeft: 10,
    zIndex: 9000,
    height: 90,
  },
  ratingHeaderViewstyle: {
    marginLeft: 10,
    marginRight: 20,
    flexDirection: windowWidth < fixedWidth ? 'column' : 'row',
    height: windowWidth < fixedWidth ? 130 : 40,
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 120,
  },
  searchBarViewStyle: {
    borderColor: colors.SimonGray,
    borderRadius: 5,
    borderWidth: 1,
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 5,
    marginTop: -5,
    height: 30,
  },
  passwordViewStyle: {
    position: 'absolute',
    zIndex: 9101991,
    alignSelf: 'center',
    height: windowHeight / 1.05,
    width: windowWidth,
    backgroundColor: colors.transparentBlackColor,
    alignItems: 'center',
    justifyContent: 'center',
  },
  backBtnBackViewStyle: {
    height: 40,
    width: 40,
    marginLeft: 16,
    backgroundColor: colors.UltraGreen,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
  },
  navigationViewStyle: {
    margin: 10,
    flexDirection: 'row',
    borderRadius: 5,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    padding: 0,
    alignItems: 'center',
    height: itsMobileView ? 110 : 60,
    marginTop: 2,
    width: 'auto',
    backgroundColor: colors.AppWhite,
    marginBottom: 0,
    zIndex: 10,
  },
  headerContainer: {
    flexDirection: itsMobileView ? 'column' : 'row',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  headerListViewcontainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 'auto',
  },
  backBtnStyle: {
    height: 15,
    width: 15,
  },
  saveBtnTxtStyle: {
    ...button1,
    color: colors.AppWhite,
    padding: 9,
    // textAlign: alignment,
  },
  darkButtonViewStyle: {
    borderRadius: 5,
    marginRight: 10,
    zIndex: 2,
    borderRadius: 5,
    borderColor: colors.AppNewGreen,
    borderWidth: 1,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.1,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
  },
  secondButtonViewStyle: {
    borderRadius: 5,
    marginRight: 10,
    zIndex: 2,
    borderRadius: 5,
    borderColor: colors.AppNewGreen,
    borderWidth: 1,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.1,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 5,
  },
  secondBtnTextStyle: {
    color: colors.AppNewGreen,
    ...button1,
    padding: 8,
  },
  acceptBtnTextStyle: {
    ...b2,
    color: colors.AppNewGreen,
    padding: 5,
  },
  orderAmountTxtStyle: {
    ...h3,
    textAlign: 'left',
    marginLeft: -10,
    paddingTop: 10,
  },
  headerSubContainer: {
    flexDirection: itsMobileView ? 'column' : 'row',
    justifyContent: 'space-between',
    width: '100%',
    flex: 1,
  },
  headerbuttonContainerStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
    marginTop: itsMobileView ? 10 : 0,
  },
  headerTitleContainerViewStyle: {
    zIndex: 101,
    width: itsMobileView ? '40%' : '25%',
    flexDirection: 'row',
    alignItems: 'center',
    height: 30,
  },
  dragIconStyle: {
    height: 15,
    width: 15,
  },
  dragdropViewStyle: {
    width: 25,
    height: 70,
    paddingLeft: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  cateSubTitle: {
    marginTop: 5,
    ...b2,
    paddingLeft: 10,
  },
  navContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: colors.AppLightGreen,
    zIndex: 101,
  },
  subContainerView: {
    height: itsMobileView ? windowHeight - 110 : windowHeight - 120,
  },
});
