import React from 'react';

const FilterOption = ({current_status, onUpdateStatus}) => {
  const button_click = status => {
    onUpdateStatus(status);
  };
  return (
    <div className="px-5 my-5">
      <div className="flex items-center gap-2 justify-between ">
        <p className=" max-w-[248px] w-full  text-base font-medium text-black">
          Prefill for
        </p>
        <div className="flex items-center justify-start gap-2 flex-grow">
          <button
            onClick={() => button_click('first_item')}
            className={
              current_status === 'first_item'
                ? 'btn-primary-fill'
                : 'btn-primary-outline '
            }>
            First Item
          </button>
          <button
            onClick={() => button_click('all_items')}
            className={
              current_status === 'all_items'
                ? 'btn-primary-fill'
                : 'btn-primary-outline  '
            }>
            All Items
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterOption;
