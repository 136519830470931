import {Text, View, Dimensions} from 'react-native';
import React from 'react';
import tableStyle from '../../StyleSheets/TableCommonStyleSheet';
import AppConstants from '../../Constants/AppConstants';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;
let fixedWidth = AppConstants.mobileMaxWidth;
let itsMobileView = windowWidth < fixedWidth ? true : false;

const CommonHeader = ({title, last_column}) => {
  return (
    <View style={tableStyle.commonHeaderViewstyle}>
      <View style={tableStyle.headerTitleContainerViewStyle}>
        <Text style={[tableStyle.titleTextStyle, {margin: 0}]}>{title} </Text>
      </View>
      {/* <View style={{zIndex: 10}}>
          <this.renderHeaderBtn />
        </View> */}

      <View style={{flexDirection: 'col'}}>
        <View style={tableStyle.commonRightTopViewStyle}>{last_column}</View>
      </View>
    </View>
  );
};

export default CommonHeader;
