import React from 'react';
import SubscriptionCard from './SubscriptionCard';

const SubscriptionList = ({
  subscription_list,
  setIsLoading,
  load_subscription_list,
  onEditSubscription,
}) => {
  // const

  return (
    <div className="grid  grid-cols-2 md:grid-cols-3 gap-4">
      {subscription_list?.map(item => {
        return (
          <SubscriptionCard
            key={item.id}
            subscription={item}
            setIsLoading={setIsLoading}
            load_subscription_list={load_subscription_list}
            onEditSubscription={onEditSubscription}
          />
        );
      })}
    </div>
  );
};

export default SubscriptionList;
