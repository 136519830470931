import networkService from '../../Network/NetworkManager';
const APPURL = require('../../Constants/URLConstants');

export const SaveConfigsDataByKey = async ({
  config_key_group,
  config_key_name,
  value,
}) => {
  try {
    let configs = [
      {
        key: config_key_name,
        key_group: config_key_group,
        secured: false,
        value: value,
      },
    ];

    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.config,
      'post',
      JSON.stringify({configs: configs}),
    );

    return {responseJson};
  } catch (error) {
    return error;
  }
};
