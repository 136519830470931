import React, {useState, useEffect, useRef} from 'react';
import {SketchPicker} from 'react-color';

const ColorConfigurationSetup = ({
  selected_config,
  setListingStates,
  current_key,
  configuration_states,
  parent_key,
}) => {
  const current_config = configuration_states?.find(
    cn => cn?.key_name === current_key,
  );
  const [isColorPlateOpen, setIsColorPlateOpen] = useState(false);
  const pickerRef = useRef(null); // Ref for detecting outside clicks

  // Close color picker when clicking outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setIsColorPlateOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [pickerRef]);

  return (
    <div className="relative">
      <button
        onClick={() => setIsColorPlateOpen(!isColorPlateOpen)}
        className="flex items-center justify-start gap-2 border rounded-sm border-simSimonGray pl-2">
        {current_config ? current_config?.value : '#01544E'}

        <span
          className="h-10 w-10"
          style={{
            background: current_config ? current_config?.value : '#01544E',
          }}></span>
      </button>
      {isColorPlateOpen && (
        <div ref={pickerRef} className="absolute top-12 z-50">
          <SketchPicker
            color={current_config ? current_config?.value : '#01544E'}
            onChange={color => {
              const old = configuration_states?.filter(
                cn => cn?.key_name !== current_key,
              );

              setListingStates([
                ...old,
                {
                  plan: '',
                  device: '',
                  country: '',
                  key_name: current_key,
                  parent_key: parent_key ?? null,
                  value: color?.hex,
                },
              ]);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ColorConfigurationSetup;
