import React from 'react';
import {StyleSheet, View, I18nManager, Platform} from 'react-native';
import MyStack from './MainRoots/Route';
import RootView from './MainRoots/RootView';
import {LogBox} from 'react-native';
import Rollbar from 'rollbar';
import TagManager from 'react-gtm-module';
import {ErrorBoundary} from '@sentry/react';
import AppConstants from '../src/Constants/AppConstants';
import {getValuesFromCookies} from './HelperClasses/UserPrefrences';
import {LocalStrings} from './LocalizedStrings/LocalStrings';
import LocalizedStrings from 'react-localization';
import Root from './Routes/root';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/index.css';
import './styles/utility-patterns.css';

import AllRoutes from './MainRoots/AllRoutes';
let strings = new LocalizedStrings(LocalStrings);
const AppConstant = require('../src/Constants/AppConstants');
const tagManagerArgs = {
  gtmId: 'GTM-N6RFH7D',
};
const lang = 'ar';

export default function App() {
  React.useEffect(() => {
    let lng = getValuesFromCookies('appLanguage');
    AppConstant.appLanguage = lng;
    strings.setLanguage(lng);
    I18nManager.allowRTL(lng == lang ? true : false);
    I18nManager.forceRTL(lng == lang ? true : false);

    LogBox.ignoreAllLogs();
    if (AppConstant.env == 'sandbox' || AppConstant.env == 'production') {
      TagManager.initialize(tagManagerArgs);
    }
    let rollbar = new Rollbar({
      accessToken: '1e3545bbe5fe472185e66be0c82915b7',
      captureUncaught: true,
      environment: AppConstant.env,
      captureUnhandledRejections: true,
      enabled: true,
    });

    if (Platform.OS === 'web') {
      document.body.style = 'height: 100%';
    }
  }, []);

  return (
    //<RootView />
    //<MyStack />
    <ErrorBoundary>
      <AllRoutes />
    </ErrorBoundary>
  );
}
