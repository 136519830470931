import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Alert,
  TextInput,
  Dimensions,
  Image,
  SafeAreaView,
  Platform,
  FlatList,
} from 'react-native';
import colors from '../../HelperClasses/AppColor';
import networkService from '../../Network/NetworkManager';
import Appload from '../../HelperClasses/AppLoaderClasses/AppLoader';
// import SlabView from './SlabView';
import Select from 'react-select';
import deleteIcon from '../../assets/delete_icon.png';
import PropTypes from 'prop-types';
import Header_View from '../../Settings/HeaderView';
import {AppToast} from '../../HelperClasses/AppToast';
import {Gradient} from 'react-gradient';
import commonStyle from '../../StyleSheets/CommonStyleSheet';
import Switch from 'react-switch';
import TableCommonStyleSheet from '../../StyleSheets/TableCommonStyleSheet';
import {
  MixPannelEnum,
  revenuModelMixPanelEvent,
} from '../../Models/MixPannelEnum';
import CommonStyleSheet from '../../StyleSheets/CommonStyleSheet';
import downArrow_Icon from '../../assets/downArrowIcon.png';
import appConfigStyle from '../../StyleSheets/AppConfigStyleSheet';
import {CommissionTypeEnum} from '../../Models/CommonEnum';
import {capitalizeFirstLetter} from '../../HelperClasses/SingletonClass';
import addIcon from '../../assets/add_icon.png';
import AppConstantsMessage from '../../Constants/AppConstantsMessage';
import ToolTipViewBtn from '../../Component/ToolTipViewBtn';
import DescriptionView from '../../Component/DescriptionView';
import AppConstants from '../../Constants/AppConstants';
import {ToastContainer} from 'react-toastify';

const APPURL = require('../../Constants/URLConstants');
const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;

let itsMobileView = windowWidth < fixedWidth ? true : false;
let TypeArray = ['Tax'];
let demandTypeArray = ['Fixed', 'Percentage'];

export default class AddTaxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: true,
      dataLoad: true,
      updateUI: false,
      slab: 0,
      addAddvanceCount: 0,
      regexp: /^[0-9\.]+$/,
      taxTitle: '',
      defaultValue: '',
      id: '',
      showTypeDropDown: false,
      selectedType: TypeArray[0],
      selectedDemandArray: [],
      shortCode: '',
      optional: false,
      minimumAmount: '',
      maximumAmount: '',
      description: '',
      taxInclusiveBool: false,
      taxNotes: '',
      selectedType: 'tax',
    };
  }
  static propTypes = {
    backBtnAction: PropTypes.func,
    taxID: PropTypes.string,
    isEdit: PropTypes.bool,
  };
  componentDidMount() {
    if (this.props.isEdit == true) {
      this.setState({dataLoad: false});
      this.loadTaxDataApi();
    }
  }
  //MARK:-  APIS Method 
  loadTaxDataApi = async () => {
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.tax + '/' + this.props.taxID,
      'get',
    );
    if (responseJson['status'] == true) {
      var comisnD = responseJson['data']['tax'];
      this.setState({
        taxTitle: comisnD['title'],
        defaultValue: comisnD['default_value'],
        status: comisnD['active'],
        selectedType: capitalizeFirstLetter(comisnD['type']),
        shortCode: comisnD['short_code'],
        taxNotes: comisnD['note'],
        taxInclusiveBool: comisnD['tax_inclusive'] ?? true,
        selectedType: comisnD['type'] ?? '',
      });
      for (let a = 0; a < comisnD['tax_data'].length; a++) {
        let dict = comisnD['tax_data'][a];
        let saveObj = {
          from: dict['from'],
          to: dict['to'],
          expand: false,
          value: dict['value'],
          type: dict['type'] == '1' ? demandTypeArray[0] : demandTypeArray[1],
        };
        this.state.selectedDemandArray.push(saveObj);
        this.setState({addAddvanceCount: a + 1});
      }
      this.setState({updateUI: !this.state.updateUI});
    }
    this.setState({dataLoad: true});
  };
  addTaxApi = async () => {
    var dictParm = {
      title: this.state.taxTitle || '',
      type: 'tax',
      default_value: Number(this.state.defaultValue) || 0,
      short_code: this.state.shortCode || '',
      active: this.state.status,
      tax_inclusive: this.state.taxInclusiveBool ?? true,
    };
    if (this.state.taxNotes.length != 0) {
      dictParm['note'] = this.state.taxNotes ?? '';
    }
    if (this.state.selectedType.length != 0) {
      dictParm['type'] = this.state.selectedType ?? '';
    }
    var taxParm = [];
    for (let a = 0; a < this.state.selectedDemandArray.length; a++) {
      let demandV = this.state.selectedDemandArray[a];
      let pramDict = {
        type: demandV['type'] == demandTypeArray[0] ? 1 : 2,
        from: Number(demandV['from']),
        to: Number(demandV['to']),
        value: Number(demandV['value']),
      };
      taxParm.push(pramDict);
    }
    if (taxParm.length != 0) {
      dictParm['tax_data'] = taxParm;
    }
    // console.log('commission_data', dictParm);
    this.setState({dataLoad: false});
    var path = APPURL.URLPaths.tax;
    if (this.props.isEdit) {
      path = APPURL.URLPaths.tax + '/' + this.props.taxID;
    }
    const responseJson = await networkService.networkCall(
      path,
      this.props.isEdit ? 'put' : 'post',
      JSON.stringify({tax: dictParm}),
    );
    console.log('tax', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      revenuModelMixPanelEvent(MixPannelEnum.tax);
      this.props.backBtnAction(false);
      AppToast();
    } else {
      AppToast(responseJson);
    }
  };
  //MARK:-  Buttons 
  onHandleTextChange = text => {
    let value = text;
    if (text == '' || this.state.regexp.test(value)) {
      this.setState({defaultValue: text});
    }
  };
  onChangeShortCode = text => {
    let value = text;
    let reg = /^[a-zA-Z0-9\s]+$/;
    if (text == '' || reg.test(value)) {
      if (value.length < 11) {
        this.setState({shortCode: text.toUpperCase()});
      }
    }
  };
  //MARK:-  Button Actions
  submitButtonAction = () => {
    this.addTaxApi();
  };
  deleteCategoryButtonAction = index => {
    this.setState({addAddvanceCount: this.state.addAddvanceCount - 1});
    this.state.selectedDemandArray.splice(index, 1);
  };

  addAddvanceButtonAction = index => {
    let demandV = {
      expand: false,
      type: demandTypeArray[0],
    };
    this.state.selectedDemandArray.push(demandV);
    this.setState({
      addAddvanceCount: this.state.addAddvanceCount + 1,
    });
  };
  statusToggle = () => {
    this.setState({status: !this.state.status});
    // console.log('statusToggel', !this.state.status);
  };
  didSelectDemandTypeCellItem = item => {
    let index = item['id'];
    let demandV = this.state.selectedDemandArray[index];
    demandV['type'] = item['name'];
    this.state.selectedDemandArray[index] = demandV;
    this.showDemandTypeDropDown(index);
  };
  showDemandTypeDropDown(index) {
    this.setState({showTypeDropDown: !this.state.showTypeDropDown});
    let category = this.state.selectedDemandArray[index];
    category['expand'] =
      category['expand'] == undefined ? true : !category['expand'];
    this.state.selectedDemandArray[index] = category;
    this.setState({updateUI: !this.state.updateUI});
  }
  fromValue(value, id) {
    var txtValue = '';
    if (value == '' || this.state.regexp.test(value)) {
      txtValue = value;
    }
    let slab = this.state.selectedDemandArray[id];
    slab['from'] = txtValue;
    this.state.selectedDemandArray[id] = slab;
    this.setState({updateUI: !this.state.updateUI});
  }
  toValue(value, id) {
    let reg = /^[0-9-\b]+$/;
    var txtValue = '';
    if (value == '' || reg.test(value)) {
      txtValue = value;
    }
    let slab = this.state.selectedDemandArray[id];
    slab['to'] = txtValue;
    this.state.selectedDemandArray[id] = slab;
    this.setState({updateUI: !this.state.updateUI});
  }
  valuePercentage(value, id) {
    let slab = this.state.selectedDemandArray[id];
    var txtValue = '';
    if (slab['type'] == demandTypeArray[0]) {
      let reg = /^[0-9\.]+$/;
      if (value == '' || reg.test(value)) {
        txtValue = value;
        slab['value'] = value;
      }
    } else {
      if (value < 101 && (value == '' || this.state.regexp.test(value))) {
        slab['value'] = value;
      }
    }
    this.state.selectedDemandArray[id] = slab;
    this.setState({updateUI: !this.state.updateUI});
  }
  //MARK:-  UIs
  titleLblRender = ({title}) => {
    return (
      <View>
        <Text style={commonStyle.titleNameStyle}>
          {title}
          <Text style={{color: 'red', paddingTop: 5}}> *</Text>
        </Text>
      </View>
    );
  };
  demandTypeDropDown = show => {
    if (show['show'] == true) {
      return (
        <View style={styles.dropDownBottomView}>
          <FlatList
            data={[
              {name: demandTypeArray[0], id: show['dIndex']},
              {name: demandTypeArray[1], id: show['dIndex']},
            ]}
            horizontal={false}
            renderItem={this.renderDemandTypeDropDown}
            extraData={this.state}
            showsVerticalScrollIndicator={false}
            scrollEnabled={true}
          />
        </View>
      );
    }
    return <View></View>;
  };
  renderDemandTypeDropDown = ({item, index}) => {
    return (
      <TouchableOpacity
        onPress={() => this.didSelectDemandTypeCellItem(item)}
        style={{margin: 5, height: 20, marginBottom: 5}}>
        <View
          style={{
            justifyContent: 'center',
            width: '100%',
            borderTopWidth: 1,
            borderTopColor: colors.Lightgray,
          }}>
          <Text
            style={[
              CommonStyleSheet.dropdownCellItemTextStyle,
              {paddingTop: 5, marginLeft: 0},
            ]}>
            {item['name']}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  renderAdvancedView = () => {
    if (this.state.addAddvanceCount != 0) {
      var views = [];
      for (let i = 0; i < this.state.addAddvanceCount; i++) {
        let demandV = this.state.selectedDemandArray[i];
        var showType = demandV['expand'] || false;
        var type = demandV['type'] || '';
        var fromV = demandV['from'] ?? '';
        var toV = demandV['to'] ?? '';
        var valueV = demandV['value'] ?? '';
        let valueTitle = type == demandTypeArray[0] ? '' : '(%)';

        views.push(
          <View style={[styles.subViewContainerStyle, {zIndex: 9980 - i}]}>
            <View style={{marginTop: 0}}></View>
            <View style={styles.addCommissionViewStyle}>
              <View style={{margin: 16, flex: 3}}>
                <this.titleLblRender title={'From'} />
                <TextInput
                  style={[
                    commonStyle.commissionValueTxtFieldStyle,
                    {outline: 0},
                  ]}
                  placeholder="Enter from"
                  value={fromV}
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={value => this.fromValue(value, i)}
                />
              </View>
              <View style={{margin: 16, flex: 3}}>
                <View style={{flexDirection: 'row'}}>
                  <this.titleLblRender title={'To'} />
                  <Text style={CommonStyleSheet.hintTitleStyle}>
                    (-1 for unlimited)
                  </Text>
                </View>
                <TextInput
                  style={[
                    commonStyle.commissionValueTxtFieldStyle,
                    {outline: 0},
                  ]}
                  placeholder="Enter to"
                  value={toV}
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={value => this.toValue(value, i)}
                />
              </View>
              <View style={styles.selecyTypeViewStye}>
                <this.titleLblRender title={'Select Type'} />
                <View
                  style={{
                    flexDirection: 'column',
                    zIndex: 6030,
                    marginTop: 10,
                  }}>
                  <TouchableOpacity
                    onPress={() => this.showDemandTypeDropDown(i)}
                    style={[styles.dropDownViewStyle]}>
                    <Text style={{paddingLeft: 15}}>{type}</Text>
                    <Image
                      style={
                        showType == true
                          ? appConfigStyle.upArraowIconStyle
                          : appConfigStyle.downArraowIconStyle
                      }
                      resizeMode="contain"
                      source={downArrow_Icon}
                    />
                  </TouchableOpacity>
                  <this.demandTypeDropDown show={showType} dIndex={i} />
                </View>
              </View>
              <View style={{margin: 16, flex: 3}}>
                <this.titleLblRender title={`Value ${valueTitle}`} />
                <TextInput
                  style={[
                    commonStyle.commissionValueTxtFieldStyle,
                    {outline: 0},
                  ]}
                  placeholder="Enter Value"
                  value={valueV}
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={value => this.valuePercentage(value, i)}
                />
              </View>
              <View style={{flex: 1, flexDirection: 'row', zIndex: 6010}}>
                <TouchableOpacity
                  style={styles.deleteBtnViewStye}
                  onPress={() => this.deleteCategoryButtonAction(i)}>
                  <Image
                    style={styles.iconImage}
                    source={deleteIcon}
                    resizeMode={'center'}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>,
        );
      }
      return views;
    } else {
      return <View />;
    }
  };

  renderBottomView = () => {
    return <this.renderAdvancedView />;
  };
  renderAddRowBtnView = () => {
    return (
      <View
        style={{
          display: this.state.addAddvanceCount > 0 ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: 20,
        }}>
        <View style={TableCommonStyleSheet.secondButtonViewStyle}>
          <TouchableOpacity
            style={styles.addRowViewStyle}
            onPress={() => this.addAddvanceButtonAction()}>
            <Image
              style={TableCommonStyleSheet.iconStyle}
              resizeMode="center"
              source={addIcon}
            />
            <Text style={TableCommonStyleSheet.secondBtnTextStyle}>
              {'Add Row'}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  renderTaxView = () => {
    return (
      <View>
        <View style={[styles.subViewStyle, {alignItems: 'baseline'}]}>
          <Text style={commonStyle.titleNameStyle}>{'Note'} </Text>
          <TextInput
            style={[
              commonStyle.commissontxtFieldStyle,
              {outline: 0, minHeight: 120, paddingTop: 10},
            ]}
            value={this.state.taxNotes}
            placeholder="Enter note for your users. On Checkout Page, Your users will be able to see this info for clarity."
            placeholderTextColor={colors.placeholderColor}
            multiline={true}
            onChangeText={notes => this.setState({taxNotes: notes})}
          />
        </View>
        <View style={[commonStyle.switchViewStyle, {zIndex: 10}]}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <Text style={commonStyle.titleNameStyle}>Tax Inclusive</Text>
            <ToolTipViewBtn
              title={''}
              description={AppConstantsMessage.taxinclusiveMsg}
            />
          </View>
          <View
            style={{
              width: itsMobileView ? '12%' : '80%',
              flexDirection: 'row',
            }}>
            <Switch
              onColor={colors.AppNewGreen}
              onChange={() =>
                this.setState({taxInclusiveBool: !this.state.taxInclusiveBool})
              }
              checked={this.state.taxInclusiveBool}
              height={22}
              width={50}
            />
          </View>
        </View>
      </View>
    );
  };

  renderTypeView = () => {
    const typesArray = [
      {label: 'Order', value: 'tax'},
      {label: 'Listing', value: 'listing_tax'},
    ];
    var view = [];
    for (let a = 0; a < 2; a++) {
      let item = typesArray[a];
      let check = this.state.selectedType === item?.value ? true : false;
      view.push(
        <TouchableOpacity
          style={check ? styles.selecedTypesViewStyle : styles.typesViewStyle}
          onPress={id => this.setState({selectedType: item?.value})}>
          <Text style={styles.titleStyle}>{item?.label}</Text>
        </TouchableOpacity>,
      );
    }
    return <View style={styles.typesViewContainerStyle}>{view}</View>;
  };

  render() {
    let isEdit = this.props.isEdit;
    return (
      <SafeAreaView style={styles.Container}>
        <ToastContainer />
        <Header_View
          title={isEdit ? 'Edit Tax' : 'Add Tax'}
          backBtn={this.props.backBtnAction}
          saveBtn={() => this.submitButtonAction()}
        />
        <ScrollView>
          <View style={styles.mainView}>
            <View style={styles.listItemView}>
              <View style={{height: 15, width: '100%'}} />
              <View style={styles.dropDownView}>
                <this.titleLblRender title={'Select Type'} />
                <View style={{width: '80%'}}>
                  <this.renderTypeView />
                </View>
              </View>
              <View style={styles.subViewStyle}>
                <this.titleLblRender title={'Title'} />
                <TextInput
                  style={[commonStyle.commissontxtFieldStyle, {outline: 0}]}
                  value={this.state.taxTitle}
                  placeholder="Enter a name. This will be shown to your users in checkout page and order details."
                  placeholderTextColor={colors.placeholderColor}
                  onChangeText={name => this.setState({taxTitle: name})}
                />
              </View>
              <View
                style={[
                  styles.subViewStyle,
                  {
                    display:
                      this.state.selectedType == CommissionTypeEnum.cart
                        ? 'none'
                        : 'flex',
                  },
                ]}>
                <this.titleLblRender title={'Default value (%)'} />
                <TextInput
                  style={[commonStyle.commissontxtFieldStyle, {outline: 0}]}
                  placeholder="Default Value in %. Example: 6.5"
                  placeholderTextColor={colors.placeholderColor}
                  keyboardType={'decimal-pad'}
                  value={this.state.defaultValue}
                  onChangeText={text => this.onHandleTextChange(text)}
                />
              </View>
              <View style={[styles.subViewStyle, {zIndex: 101}]}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                  <this.titleLblRender title={'Short Code'} />
                  <ToolTipViewBtn
                    title={AppConstantsMessage.shortCodeTitleToopTipMsg}
                    description={AppConstantsMessage.shortCodeSubTitleToolTipMsg.replace(
                      'commission',
                      'taxes',
                    )}
                  />
                </View>
                <TextInput
                  editable={isEdit ? false : true}
                  style={[commonStyle.commissontxtFieldStyle, {outline: 0}]}
                  placeholder="Only Alphabets & Number. Maximum is 10. Example (GST10) "
                  placeholderTextColor={colors.placeholderColor}
                  keyboardType={'decimal-pad'}
                  value={this.state.shortCode}
                  onChangeText={text => this.onChangeShortCode(text)}
                />
              </View>
              <View style={{zIndex: 100}}>{this.renderTaxView()}</View>
              <View style={[commonStyle.switchViewStyle, {zIndex: 10}]}>
                <Text style={commonStyle.titleNameStyle}>
                  Status(Not Active / Active){' '}
                </Text>
                <View
                  style={{
                    width: itsMobileView ? '12%' : '80%',
                    flexDirection: 'row',
                  }}>
                  <Switch
                    onColor={colors.AppNewGreen}
                    onChange={this.statusToggle}
                    checked={this.state.status}
                    height={22}
                    width={50}
                  />
                </View>
              </View>
              <View
                style={{
                  alignItems: 'flex-end',
                  overflow: 'hidden',
                  paddingRight: 10,
                  display:
                    this.state.selectedType == CommissionTypeEnum.cart
                      ? 'none'
                      : 'flex',
                }}>
                <View style={TableCommonStyleSheet.secondButtonViewStyle}>
                  <TouchableOpacity
                    disabled={this.state.addAddvanceCount == 0 ? false : true}
                    onPress={() => this.addAddvanceButtonAction()}>
                    <Text style={TableCommonStyleSheet.secondBtnTextStyle}>
                      {'Advanced'}
                    </Text>
                  </TouchableOpacity>
                </View>
              </View>
              <View style={{zIndex: 500}}>{this.renderBottomView()}</View>
              <View style={{zIndex: 200}}>{this.renderAddRowBtnView()}</View>
              <View style={{height: 30, width: '100%'}} />
            </View>
          </View>
        </ScrollView>
        <View style={{height: 50, width: '100%'}} />
        <Appload enable={this.state.dataLoad} />
      </SafeAreaView>
    );
  }
}
const styles = StyleSheet.create({
  Container: {
    flex: 1,
    backgroundColor: colors.AppLightGreen,
  },
  mainView: {
    margin: 10,
    backgroundColor: 'white',
    flexDirection: 'column',
    padding: 5,
    borderRadius: 5,
    borderColor: colors.SimonGray,
    borderWidth: 1,
  },
  listItemView: {
    backgroundColor: 'white',
    width: '100%',
  },
  subViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 20,
    zIndex: 1,
  },
  submitViewBtnStyle: {
    padding: 7,
    borderRadius: 15,
    backgroundColor: colors.Appgreen,
    alignSelf: 'center',
    width: 80,
  },
  resetViewBtnStyle: {
    padding: 7,
    borderRadius: 15,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    alignSelf: 'center',
    width: 80,
  },
  statusViewStyle: {
    flexDirection: 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 20,
    alignItems: 'center',
    zIndex: 5,
  },
  switchStyle: {
    marginLeft: 190,
  },
  addCommissionViewStyle: {
    flexDirection: itsMobileView ? 'column' : 'row',
    zIndex: 6001,
  },
  selecyCategoryViewStye: {
    margin: 16,
    backgroundColor: 'white',
    flex: 6,
    // height: 100,
    zIndex: 60090,
  },
  selecyTypeViewStye: {
    margin: 16,
    flex: 3,
    zIndex: 60080,
  },
  selecyValueViewStye: {
    margin: 16,
    backgroundColor: 'white',
    flex: 2,
    height: 100,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.4,
    shadowOffset: {width: 2, height: 2},
    shadowRadius: 5,
    backgroundColor: 'red',
  },
  deleteBtnViewStye: {
    margin: 16,
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  ValueViewStyle: {
    margin: 16,
    backgroundColor: 'white',
    flex: 2,
    height: 100,
    zIndex: 43,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.4,
    shadowOffset: {width: 2, height: 2},
    shadowRadius: 5,
  },
  dropDownView: {
    flexDirection: itsMobileView ? 'column' : 'row',
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    justifyContent: 'space-between',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    marginBottom: 5,
    zIndex: 11,
  },
  dropDownViewStyle: {
    borderRadius: 5,
    borderWidth: 1,
    borderColor: colors.Lightgray,
    width: '80%',
    marginLeft: 0,
    height: 40,
    alignItems: 'center',
    zIndex: 102,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dropDownBottomView: {
    backgroundColor: 'white',
    position: 'absolute',
    borderRadius: 5,
    borderWidth: 1,
    borderTopWidth: 0,
    borderTopEndRadius: 0,
    borderTopStartRadius: 0,
    borderColor: colors.Lightgray,
    width: '80%',
    marginTop: 30,
    zIndex: 105,
    shadowColor: colors.Appgray,
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 2},
    shadowRadius: 1,
    height: 70,
  },
  iconImage: {
    height: 26,
    width: 26,
    alignSelf: 'center',
  },
  subViewContainerStyle: {
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: colors.Lightgray,
    margin: 20,
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 2,
    borderRadius: 10,
  },
  addRowViewStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  selecedTypesViewStyle: {
    marginRight: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.Appgreen,
    borderWidth: 1,
    height: 40,
    width: 60,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
    marginLeft: 0,
  },
  typesViewStyle: {
    marginRight: 20,
    backgroundColor: 'white',
    flexDirection: 'column',
    borderRadius: 10,
    borderColor: colors.SimonGray,
    borderWidth: 1,
    height: 40,
    width: 60,
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
  },
  typesViewContainerStyle: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: itsMobileView ? 'flex-start' : 'center',
    backgroundColor: 'white',
    width: itsMobileView ? windowWidth : windowWidth / 2,
  },
});
