import React, {useEffect, useState} from 'react';
import FeatureCard from '../UI/cards/FeatureCard';
import CardWithButton from '../UI/cards/CardWithButton';
import TutorialCard from '../UI/cards/TutorialCard';
import Title from '../UI/Typography/Title';
import {c2c_dashboard_data} from '../Constants/C2CDashboardData';
import {b2c_dashboard_data} from '../Constants/B2CDashboardData';
import Heading3 from '../UI/Typography/Heading3';
import Paragraph from '../UI/Typography/Paragraph';
import LinkableButton from '../UI/Buttons/LinkableButton';
import {useNavigate} from 'react-router-dom';
import AppConstants from '../Constants/AppConstants';
import {MixpanelEvent, addMixpanelEvent} from '../Models/MixPannelEnum';
import gift_icon from '../assets/gift.png';
import {make_id_by_title} from '../utils/makeId';
import {getItemFromStorage, setItemInStorage} from '../utils/localStorage';

const DashboardScreen2 = () => {
  const navigate = useNavigate();
  const [dashboard_data, setDashboardData] = useState(
    AppConstants.appType ? b2c_dashboard_data : c2c_dashboard_data,
  );

  // initialize dashboard
  useEffect(() => {
    setDashboardData(
      AppConstants.appType ? b2c_dashboard_data : c2c_dashboard_data,
    );
    mixPanelHandler();
  }, [AppConstants?.appType, AppConstants?.domainKey]);

  // buttonClickHandler
  const buttonClickHandler = item => {
    if (item?.is_external_link) {
      let link = item?.external_link_by_domain
        ? `${AppConstants.domainKey}${item?.link}`
        : item?.link;

      window.open(link);
    } else {
      navigate(item?.link);
    }

    // mixpanel
    mixPanelHandler(item);
  };

  //  mixpanel triggered
  const mixPanelHandler = item => {
    let key_name = `${AppConstants?.domainKey}get-started`;
    let title = item?.title ? item?.title : 'get started page loaded';

    let prev_triggers = getItemFromStorage({key: key_name}) ?? [];

    if (!prev_triggers.includes(make_id_by_title(title))) {
      addMixpanelEvent(
        MixpanelEvent.GetStarted,
        item?.title ? {Action: item?.title} : undefined,
      );
      setItemInStorage({
        key: key_name,
        value: [...prev_triggers, make_id_by_title(title)],
      });
    }
  };

  return (
    <div className=" mt-8 w-full  max-h-screen overflow-auto pb-20">
      <div className="p-5   md:p-10 max-w-6xl mx-auto space-y-10">
        <div>
          <Title>
            Welcome to Tradly Platform, {AppConstants.userFullName}!
          </Title>
          <Paragraph className={'text-center mt-4 '}>
            Here is a checklist for you to setup and manage your project.
          </Paragraph>
        </div>
        {/*  */}
        <div className="grid grid-cols-1 md:grid-cols-2">
          <CardWithButton
            component_styles={'py-10 md:space-y-8'}
            data={{
              title: dashboard_data.main_card.title,
              description: dashboard_data.main_card.description,
              btn_title: dashboard_data.main_card.button_title ?? 'Add Now ',
            }}
            oncClickHandler={() =>
              buttonClickHandler(dashboard_data?.main_card)
            }
          />
          <iframe
            width="100%"
            className="max"
            height="315"
            src="https://www.youtube.com/embed/aFzv9s7VOsA?si=G_Vi9QpUHiXi4AHm"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen></iframe>
        </div>

        {/*  */}
        <div className=" grid grid-cols-1 md:grid-cols-2 gap-6    ">
          {dashboard_data?.main_features?.map(m_feature => {
            return (
              <CardWithButton
                key={m_feature.title}
                button_type="secondary"
                data={{
                  title: m_feature.title,
                  description: m_feature.description,
                  btn_title: m_feature?.button_title ?? 'Start building ',
                }}
                oncClickHandler={() => {
                  buttonClickHandler(m_feature);
                }}
              />
            );
          })}
        </div>
        {/* feature list */}
        <div className="space-y-10">
          {dashboard_data?.features_list?.map(feature => {
            return (
              <div className=" space-y-4">
                <Heading3>{feature?.section_title}</Heading3>
                <div className=" grid grid-cols-1 md:grid-cols-2 gap-6 ">
                  {feature?.items_list?.map(item => {
                    return (
                      <FeatureCard
                        key={item?.title}
                        title={item?.title}
                        description={item?.description}
                        component_styles={' cursor-pointer'}
                        onClickHandler={() => {
                          buttonClickHandler(item);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {/* tutorials */}
        <div className="w-full">
          <Heading3>{dashboard_data?.tutorial_data?.title}</Heading3>
          <Paragraph className="mt-2">
            {dashboard_data?.tutorial_data?.description}
          </Paragraph>

          <div className=" grid grid-cols-1 md:grid-cols-3 gap-10 mt-6">
            {dashboard_data?.tutorial_data?.video_list?.map(video => {
              return (
                <TutorialCard
                  image={video?.image}
                  title={video?.title}
                  key={video?.title}
                  video_url={video?.link}
                  onClickHandler={() => {
                    buttonClickHandler(video);
                  }}
                />
              );
            })}
          </div>
        </div>

        {/* Refer card */}
        <div className=" flex flex-col items-center justify-center gap-3  py-12 border-t border-gray-200">
          <Heading3 className="  text-center font-bold flex items-center gap-2">
            <img src={gift_icon} alt="" />
            Refer & Make Money / Credits
          </Heading3>
          <Paragraph>
            Earn 15% flat per month whenever someone signup through your
            referral link and pay for their subscription.
          </Paragraph>
          <LinkableButton
            href={'https://tradly.app/affiliate'}
            className={' text-lg font-semibold'}>
            Learn More.
          </LinkableButton>
        </div>
      </div>
    </div>
  );
};

export default DashboardScreen2;
