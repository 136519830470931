export var CONFIG_CONSTANT = {
  GENERAL_CONFIGS: '',
  ONBOARDING_CONFIGS: '',
  ACCOUNTS_CONFIGS: '',
  LISTINGS_CONFIGS: '',
  ALL_INTEGRATIONS: '',
  PAYMENT_CONFIGS: '',
  DRAFT_STYLES: '',
  SEO_CONFIGS: '',
  STYLES_CONFIGS: '',
  SOCIAL_CONFIGS: '',
  ADDRESS_CONFIGS: '',
  ORDERS_CONFIGS: '',
  SHIPPING_CONFIGS: '',
  PAGES: '',
  SUPERADMIN: '',
};

// fn
export const set_root_configs = ({all_configs}) => {
  CONFIG_CONSTANT.GENERAL_CONFIGS = all_configs?.general ?? {};
  CONFIG_CONSTANT.ONBOARDING_CONFIGS = all_configs?.onboarding ?? {};
  CONFIG_CONSTANT.ACCOUNTS_CONFIGS = all_configs?.accounts ?? {};
  CONFIG_CONSTANT.LISTINGS_CONFIGS = all_configs?.listings ?? {};
  CONFIG_CONSTANT.ALL_INTEGRATIONS = all_configs?.integrations ?? {};
  CONFIG_CONSTANT.PAYMENT_CONFIGS = all_configs?.payments ?? {};
  CONFIG_CONSTANT.DRAFT_STYLES = all_configs?.draft_styles ?? {};
  CONFIG_CONSTANT.SEO_CONFIGS = all_configs?.seo ?? {};
  CONFIG_CONSTANT.STYLES_CONFIGS = all_configs?.styles ?? {};
  CONFIG_CONSTANT.SOCIAL_CONFIGS = all_configs?.social ?? {};
  CONFIG_CONSTANT.ADDRESS_CONFIGS = all_configs?.address ?? {};
  CONFIG_CONSTANT.ORDERS_CONFIGS = all_configs?.orders ?? {};
  CONFIG_CONSTANT.SHIPPING_CONFIGS = all_configs?.shipping ?? {};
  CONFIG_CONSTANT.PAGES = all_configs?.pages ?? {};
};
