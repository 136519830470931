import React from 'react';
import Switch from 'react-switch';
import HelperVideoView from '../../../Component/HelperVideoView';

const ConfigurationBox = ({
  selected_config,
  setListingStates,
  current_key,
  configuration_states,
  parent_key,
}) => {
  return (
    <div className=" md:w-full flex items-center gap-4">
      <Switch
        onColor={'#01544E'}
        onChange={() => {
          const old = configuration_states?.filter(
            cn => cn?.key_name !== current_key,
          );
          const current_config = configuration_states?.find(
            cn => cn?.key_name === current_key,
          );

          setListingStates([
            ...old,
            {
              plan: '',
              device: '',
              country: '',
              key_name: current_key,
              parent_key: parent_key ?? null,
              feature_activated:
                current_config?.feature_activated === true ? false : true,
            },
          ]);
        }}
        checked={selected_config?.feature_activated}
        height={22}
        width={50}
      />
    </div>
  );
};

export default ConfigurationBox;
