import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  FlatList,
  Dimensions,
  Platform,
  SafeAreaView,
  Image,
} from 'react-native';
import Appload from '../HelperClasses/AppLoaderClasses/AppLoader';
import colors from './../HelperClasses/AppColor';
import networkService from './../Network/NetworkManager';
import tableStyle from './../StyleSheets/TableCommonStyleSheet';
import edit_Icon from '../assets/editIcon.png';
import deleteIcon from '../assets/delete_icon.png';
import disableIcon from '../assets/disableIcon.png';
import placeholder from '../assets/pPlaceholder.png';
import PropTypes from 'prop-types';
import EmptyListUI from '../Component/AppEmptyList';
import {ToastContainer} from 'react-toastify';
import {Gradient} from 'react-gradient';
import AddCoupon from './AddCoupon';
import {AppToast} from '../HelperClasses/AppToast';
import appMessage from '../Constants/AppConstantsMessage';
import {
  capitalizeFirstLetter,
  checkUserRoleModule,
  dateConversionFromString,
  dateConversionFromTimeStamp,
  getThumbnailImage,
} from '../HelperClasses/SingletonClass';
import AppConstants from '../Constants/AppConstants';
import {MODULEKEYS} from '../Models/Enum';
import Moment from 'moment';
import {
  addMixpanelEvent,
  MixpanelEvent,
  MixPannelEnum,
} from '../Models/MixPannelEnum';
import CommonStyleSheet from '../StyleSheets/CommonStyleSheet';
import ToolTipViewBtn from '../Component/ToolTipViewBtn';
import AppConstantsMessage from '../Constants/AppConstantsMessage';
import HelperLinkView from '../Component/HelperLinkView';
import {withRouter} from '../wrappers/withRouter';

const APPURL = require('./../Constants/URLConstants');

const windowWidth = Dimensions.get('window').width;
let fixedWidth = AppConstants.mobileMaxWidth;
var pageNo = 1;
let PerPage = 25;
let itsMobileView = windowWidth < fixedWidth ? true : false;

class Coupons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      selectedType: 'all',
      couponsArray: [],
      dataLoad: false,
      selectedCouponIndex: 0,
      isEdit: false,
      addCouponBool: false,
      collectionID: 0,
      stopPage: false,
      haveData: false,
      showGridView: false,
      showFilterView: false,
      showCalendarView: false,
      writeP: AppConstants.defaultWrite,
      deleteP: AppConstants.defaultDelete,
      perPage: PerPage,
    };
  }
  static propTypes = {
    saveBtnAction: PropTypes.func,
  };
  componentDidMount() {
    this.mixPannelInfo();
    this.getApi();
    this.checkModule();
  }
  checkModule = () => {
    checkUserRoleModule(MODULEKEYS.COUPONS, res => {
      this.setState({writeP: res[1], deleteP: res[2]});
    });
  };
  mixPannelInfo() {
    addMixpanelEvent(MixpanelEvent.viewCouponsList, {page: pageNo});
  }
  getApi() {
    pageNo = 1;
    this.loadCouponApi();
  }
  //MARK:-  APIS Method 
  loadCouponApi = async type => {
    this.setState({dataLoad: false});
    let path = `?page=${pageNo}&per_page=${this.state.perPage}`;
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.coupons + path,
      'get',
    );
    this.setState({couponsArray: []});
    if (responseJson['status'] == true) {
      var coupons = responseJson['data']['coupons'];
      if (coupons.length != 0) {
        for (let objc of coupons) {
          this.state.couponsArray.push(objc);
        }
      } else {
        pageNo = pageNo <= 1 ? 1 : pageNo - 1;
        this.setState({stopPage: true});
      }
      this.state.haveData = this.state.couponsArray.length == 0 ? true : false;
    }
    this.setState({dataLoad: true});
  };
  deleteCouponApi = async id => {
    this.setState({dataLoad: false});
    const responseJson = await networkService.networkCall(
      APPURL.URLPaths.coupons + '/' + id,
      'delete',
    );
    // console.log('res po', responseJson);
    this.setState({dataLoad: true});
    if (responseJson['status'] == true) {
      this.mixpanelDeleteInfo(true);
      this.alertView('delete');
      this.loadCouponApi(this.state.selectedType);
    } else {
      this.mixpanelDeleteInfo(false);
    }
  };
  mixpanelDeleteInfo(resp) {
    let properties = {success: resp};
    addMixpanelEvent(`${MixpanelEvent.deletePromotion}`, properties);
  }
  alertView(title) {
    AppToast(title);
  }
  //MARK:- Button Actions
  backBtnHandler() {
    this.setState({addCouponBool: false});
  }
  nextPreviousBtnAction(index) {}
  addPromoBannerBtnAction() {
    this.props.navigate(`/add-coupon`);
    this.loadCouponApi(this.state.selectedType);
  }
  didSelectDropDown = item => {
    this.setState({showDropDown: false, selectedType: item});
    this.loadCouponApi(item);
  };
  editButtonAction = index => {
    // console.log('index', index);
    this.props.navigate(`/edit-coupon/${index}`);
  };
  dtlCouponBtnAction = index => {
    if (Platform.OS === 'web') {
      const r = window.confirm('Do you want to go delete this coupon');
      if (r == true) {
        this.deleteCouponApi(index);
      }
    }
  };

  nextPreviousBtnAction(index) {
    if (index == 1) {
      if (!this.state.stopPage) {
        pageNo = pageNo + 1;
        this.loadCouponApi();
      }
    } else if (index == 2) {
      if (pageNo == 1) {
      } else {
        pageNo = pageNo - 1;
        this.loadCouponApi();
      }
    }
    this.mixPannelInfo();
    this.setState({updateUI: !this.state.updateUI});
  }
  /*  UI   */
  tableViewHeader = props => {
    return (
      <FlatList
        data={tableHeaderString}
        horizontal={true}
        renderItem={this.tableHeaderCell}
        extraData={this.state}
        showsVerticalScrollIndicator={false}
        scrollEnabled={false}
        style={{margin: 0}}
        keyExtractor={(item, index) => index + 7989}
        key={'H'}
      />
    );
  };
  tableHeaderCell = ({item, index}) => {
    return (
      <View style={styles.tableViewTitleViewStyle}>
        <Text style={tableStyle.tableViewTitleStyle}>{item}</Text>
      </View>
    );
  };
  columnDataView = props => {
    var views = [];
    for (let i = 0; i < this.state.couponsArray.length; i++) {
      let couponsType = this.state.couponsArray[i]['coupon_type'].replace(
        '_',
        ' ',
      );
      views.push(
        <FlatList
          data={[
            this.state.couponsArray[i]['reference_name'],
            this.state.couponsArray[i]['code'],
            `${capitalizeFirstLetter(couponsType)}`,
            this.state.couponsArray[i]['start_at'],
            this.state.couponsArray[i]['end_at'],
            this.state.couponsArray[i]['active'] == true
              ? 'Active'
              : 'Inactive',
            this.state.couponsArray[i]['id'],
          ]}
          horizontal={true}
          renderItem={this.columnCell}
          extraData={this.state}
          showsVerticalScrollIndicator={false}
          scrollEnabled={true}
          style={{
            borderBottomWidth: 1,
            borderBottomColor: colors.SimonGray,
            backgroundColor: 'white',
          }}
          keyExtractor={(item, index) => index + 9989}
          key={'H'}
        />,
      );
    }
    return views;
  };
  columnCell = ({item, index}) => {
    if (index == 5) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text
            style={
              item != 'Active'
                ? tableStyle.columnDeActiveBtnStyle
                : tableStyle.columnActiveBtnStyle
            }>
            {item}
          </Text>
        </View>
      );
    } else if (index == 6) {
      return (
        <View style={styles.columnTitleViewStyle}>
          {this.renderEditDeleteView(item)}
        </View>
      );
    } else if (index == 0) {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text style={tableStyle.mainTitleStyle}>{item}</Text>
        </View>
      );
    } else {
      return (
        <View style={styles.columnTitleViewStyle}>
          <Text numberOfLines={1} style={tableStyle.columnViewTitleStyle}>
            {item}
          </Text>
        </View>
      );
    }
  };
  renderEditBtn = item => {
    return (
      <TouchableOpacity
        disabled={!this.state.writeP}
        onPress={() => this.editButtonAction(item)}>
        <Image
          style={tableStyle.iconStyle}
          resizeMode="center"
          source={this.state.writeP ? edit_Icon : disableIcon}
        />
      </TouchableOpacity>
    );
  };
  renderDeleteBtn = item => {
    return (
      <TouchableOpacity
        disabled={!this.state.deleteP}
        onPress={() => this.dtlCouponBtnAction(item)}>
        <Image
          style={tableStyle.iconStyle}
          resizeMode="center"
          source={this.state.deleteP ? deleteIcon : disableIcon}
        />
      </TouchableOpacity>
    );
  };
  gradientButtons = name => {
    if (this.state.couponsArray.length != 0 || pageNo > 1) {
      if (name['id'] == 2 && pageNo == 1) {
        return <View />;
      } else if (
        name['id'] == 1 &&
        this.state.couponsArray.length == 0 &&
        pageNo > 1
      ) {
        return <View />;
      }
      return (
        <View>
          <View style={tableStyle.grandientBGStyle}>
            <Gradient
              gradients={colors.GradientColors} // required
              property="background"
              gradientType={'linear'}
              duration={2333000}
              style={{
                height: 30,
                width: 100,
                borderRadius: 10,
                marginRight: 30,
              }}
              angle="0deg">
              <TouchableOpacity
                style={tableStyle.nextPreviousViewStyle}
                onPress={() => this.nextPreviousBtnAction(name['id'])}>
                <Text style={{color: 'white'}}>{name['name']}</Text>
              </TouchableOpacity>
            </Gradient>
          </View>
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderBottomView = () => {
    if (this.state.totalRecords > this.state.perPage) {
      return (
        <View style={tableStyle.bottomViewStyle}>
          <this.gradientButtons name={'Next'} id={1} />
          <this.gradientButtons name={'Previous'} id={2} />
        </View>
      );
    } else {
      return <View />;
    }
  };
  renderEditDeleteView = item => {
    return (
      <View style={{flexDirection: 'row'}}>
        <TouchableOpacity onPress={() => this.editButtonAction(item)}>
          <Image
            style={tableStyle.iconStyle}
            resizeMode="center"
            source={edit_Icon}
          />
        </TouchableOpacity>
        <View style={{width: 10}} />
        <TouchableOpacity onPress={() => this.dtlCouponBtnAction(item)}>
          <Image
            style={tableStyle.iconStyle}
            resizeMode="center"
            source={deleteIcon}
          />
        </TouchableOpacity>
      </View>
    );
  };
  renderAddCouponsBtn = () => {
    return (
      <View style={tableStyle.gradientViewBGStyle}>
        <Gradient
          gradients={colors.GradientColors} // required
          property="background"
          gradientType={'linear'}
          duration={2333000}
          angle="0deg">
          <TouchableOpacity onPress={() => this.addPromoBannerBtnAction()}>
            <Text style={tableStyle.saveBtnTxtStyle}>{'Add Coupon'}</Text>
          </TouchableOpacity>
        </Gradient>
      </View>
    );
  };

  renderHeaderView = () => {
    return (
      <View style={tableStyle.headerViewstyle}>
        <View style={[tableStyle.headerSubContainer, {flexDirection: 'row'}]}>
          <View style={{flexDirection: 'row', alignItems: 'center', flex: 1}}>
            <Text style={[tableStyle.titleTextStyle, {margin: 0}]}>
              Coupons{' '}
            </Text>
          </View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              zIndex: 4,
              flex: 1,
            }}></View>
          <View style={tableStyle.headerbuttonContainerStyle}>
            <this.renderAddCouponsBtn />
          </View>
        </View>
      </View>
    );
  };
  EmptyListRender = () => {
    if (this.state.couponsArray.length == 0) {
      if (this.state.haveData) {
        return (
          <View style={{height: '100%', justifyContent: 'center'}}>
            <EmptyListUI
              messageIcon={5}
              showImage={true}
              titleString={appMessage.emptyCouponsTitleString}
              subtitleString={appMessage.couponsMsgTextString}
            />
          </View>
        );
      } else {
        return <View />;
      }
    } else {
      return <View />;
    }
  };
  renderListView = () => {
    return (
      <ScrollView style={tableStyle.mainScrollViewStyle} horizontal={true}>
        <View>
          <View style={tableStyle.headerListContainer}>
            <this.tableViewHeader />
          </View>
          <ScrollView style={styles.tableViewHeaderStyle}>
            <this.columnDataView />
          </ScrollView>
        </View>
      </ScrollView>
    );
  };
  renderMainView = () => {
    return <this.renderListView />;
  };
  render() {
    if (this.state.addCouponBool) {
      return (
        <View>
          <ToastContainer />
          <AddCoupon
            isEdit={this.state.isEdit}
            couponsData={
              this.state.couponsArray[this.state.selectedCouponIndex]
            }
            backBtnAction={() => this.addPromoBannerBtnAction()}
          />
        </View>
      );
    } else {
      return (
        <SafeAreaView style={styles.Container}>
          <ToastContainer />
          <this.renderHeaderView />
          <View style={tableStyle.mainView}>
            {this.renderMainView()}
            {this.renderBottomView}
            <this.EmptyListRender />
          </View>
          <HelperLinkView title={'Coupon'} />
          <Appload enable={this.state.dataLoad} />
        </SafeAreaView>
      );
    }
  }
}
export default withRouter(Coupons);

const styles = StyleSheet.create({
  Container: {
    backgroundColor: colors.AppLightGreen,
    flex: 1,
  },
  tableViewTitleViewStyle: {
    padding: 0,
    width: itsMobileView ? 130 : windowWidth / 6.8,
    height: 40,
  },
  tableViewHeaderStyle: {
    backgroundColor: 'white',
    borderRadius: 5,
    zIndex: 1,
    width: '100%',
    padding: 0,
  },
  columnTitleViewStyle: {
    width: itsMobileView ? 130 : windowWidth / 6.8,
    justifyContent: 'flex-start',
    padding: 5,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'white',
    paddingLeft: 10,
  },
});

const tableHeaderString = [
  'Name',
  'Code',
  'Coupon Type',
  'Start Date Time',
  'End Date Time',
  'Active',
  'Action',
];
