import {ICONS} from './Icons';

export const AllMenus = [
  {
    id: 1,
    title: 'Get Started',
    icon: ICONS.home_Icon,
    sub_menus: [],
    isShow: true,
    href: '/',
    key_name: 'get_started',
  },
  {
    id: 2,
    title: 'Orders',
    icon: ICONS.orderIcon,
    sub_menus: [],
    isShow: true,
    href: '/orders',
    key_name: 'orders',
  },
  {
    id: 3,
    title: 'Accounts',
    icon: ICONS.account_Icon,
    sub_menus: [
      {
        id: 1,
        title: 'Accounts',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: '/accounts',
        key_name: 'accounts',
      },
      {
        id: 2,
        title: 'Account Categories',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: '/account-category',
        key_name: 'account_categories',
      },
      {
        id: 3,
        title: 'Account Attributes',
        icon: ICONS.seoIcon,
        isShow: true,
        href: '/account-attributes',
        key_name: 'account_attributes',
      },
      {
        id: 4,
        title: 'Account Configuration',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: '/account-configs',
        key_name: 'account_configuration',
      },
    ],
    isShow: true,
    key_name: 'account_section',
  },
  {
    id: 4,
    title: 'Listings',
    icon: ICONS.listing_Icon,
    sub_menus: [
      {
        id: 1,
        title: 'Listings',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: '/listings',
        key_name: 'listings',
      },
      {
        id: 2,
        title: 'Categories',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: '/listing-category',
        key_name: 'listing_categories',
      },
      {
        id: 3,
        title: 'Custom Attributes',
        icon: ICONS.seoIcon,
        isShow: true,
        href: '/listing-attributes',
        key_name: 'custom_attributes',
      },
      {
        id: 4,
        title: 'Variant Types',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: '/listing-variants',
        key_name: 'variant_types',
      },
      {
        id: 5,
        title: 'Additional Features',
        icon: ICONS.nativeintegrationIcon,
        isShow: true,
        href: '/listing-configs',
        key_name: 'additional_features',
      },
      {
        id: 6,
        title: 'Reported Listings',
        icon: ICONS.reportedIcon,
        sub_menus: [],
        isShow: true,
        href: '/reported-listings',
        key_name: 'reported_listings',
      },
    ],
    isShow: true,
    key_name: 'listing_section',
  },
  {
    id: 5,
    title: 'Users',
    icon: ICONS.usersIcon,
    sub_menus: [
      {
        id: 1,
        title: 'Users',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: '/users',
        key_name: 'users',
      },
      {
        id: 2,
        title: 'User Attributes',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: '/user-attributes',
        key_name: 'user_attributes',
      },
      {
        id: 3,
        title: 'Reviews & Ratings',
        icon: ICONS.reviewIcon,
        sub_menus: [],
        isShow: true,
        href: '/review-ratings',
        key_name: 'reviews_ratings',
      },
    ],
    isShow: true,
    key_name: 'user_section',
  },
  {
    id: 6,
    title: 'Report',
    icon: ICONS.reportIcon,
    sub_menus: [],
    isShow: true,
    href: '/reports',
    key_name: 'report_section',
  },
  {
    id: 7,
    title: 'Marketing',
    icon: ICONS.adBanner_Icon,
    sub_menus: [
      {
        id: 0,
        title: 'Collections',
        icon: ICONS.collectionsIcon,
        isShow: true,
        href: '/collections',
        key_name: 'collections',
      },
      {
        id: 1,
        title: 'Promotions',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: '/promotions',
        key_name: 'promotions',
      },
      {
        id: 2,
        title: 'Coupons',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: '/coupon',
        key_name: 'coupons',
      },
    ],
    isShow: true,
    key_name: 'marketing_section',
  },
  {
    id: 8,
    title: 'Finance',
    icon: ICONS.currencyIcon,
    sub_menus: [
      {
        id: 1,
        title: 'Transactions',
        icon: ICONS.transactionIcon,
        isShow: true,
        href: '/transaction',
        key_name: 'transactions',
      },
    ],
    isShow: true,
    key_name: 'finance_section',
  },
  {
    id: 10,
    title: 'Media',
    icon: ICONS.mediaIcon,
    sub_menus: [],
    isShow: true,
    href: '/media',
    key_name: 'media',
  },
  {
    id: 16,
    title: 'Communication',
    icon: ICONS.communicationIcon,
    sub_menus: [],
    isShow: true,
    href: '/communications',
    key_name: 'communication',
  },
  {
    id: 11,
    title: 'Web',
    icon: ICONS.webIcon,
    sub_menus: [
      {
        id: 1,
        title: 'Website Editor',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/editor',
        isOpenNewTab: true,
        key_name: 'website_editor',
      },
      {
        id: 2,
        title: 'Change Templates',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/workspace/templates',
        isOpenNewTab: true,
        key_name: 'change_templates',
      },
      {
        id: 4,
        title: 'Pages',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/workspace/p',
        isOpenNewTab: true,
        key_name: 'pages',
      },
      {
        id: 5,
        title: 'Blog',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/workspace/blog',
        isOpenNewTab: true,
        key_name: 'blog',
      },
      {
        id: 6,
        title: 'Customise texts',
        icon: ICONS.appTranslateIcon,
        isShow: true,
        href: '/strings-customisation',
        key_name: 'customise_texts',
      },
      {
        id: 7,
        title: 'Add Integrations',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: 'https://tradly.app/workspace/integrations',
        isOpenNewTab: true,
        key_name: 'add_integrations',
      },
      {
        id: 8,
        title: 'Domain',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/workspace/domain',
        isOpenNewTab: true,
        key_name: 'domain',
      },
    ],
    isShow: true,
    key_name: 'web_section',
  },
  {
    id: 12,
    title: 'App',
    icon: ICONS.appIcon,
    sub_menus: [
      {
        id: 1,
        title: 'Buy App Templates',
        icon: ICONS.iconGlobal,
        isShow: true,
        href: 'https://tradly.app/workspace/templates',
        isOpenNewTab: true,
        key_name: 'buy_app_templates',
      },
      {
        id: 2,
        title: 'Customisation',
        icon: ICONS.mobileIcon,
        isShow: true,
        href: '/app-customization',
        key_name: 'customisation',
      },
      {
        id: 3,
        title: 'Integrations',
        icon: ICONS.nativeintegrationIcon,
        isShow: true,
        href: '/app-integration',
        key_name: 'app_integrations',
      },
      {
        id: 4,
        title: 'Customise texts',
        icon: ICONS.appTranslateIcon,
        isShow: true,
        href: '/strings-customisation',
        key_name: 'customise_app_texts',
      },
    ],
    isShow: true,
    key_name: 'app_section',
  },

  {
    id: 17,
    title: '',
    icon: ICONS.arrowFromLeft,
    sub_menus: [],
    isShow: true,
    href: undefined,
    key_name: 'undefined',
  },

  {
    id: 1,
    title: 'Background Color',
    isShow: true,
    type: 'color',
    sub_menus: [],
    key_name: 'background_color',
    href: undefined,
  },
  {
    id: 1,
    title: 'Text Color',
    isShow: true,
    sub_menus: [],
    type: 'color',
    key_name: 'text_color',
    href: undefined,
  },

  //   {id: 16, title: 'More', icon: moreIcon, sub_menus: [], isShow: true},
];
